import { makeAutoObservable, runInAction } from "mobx";
import { Drivers } from "../models/Drivers";
import agent from "../api/agent";
import { v4 as uuid } from "uuid";
import { store } from "./store";

export default class DriverStore {
  driverRegistry = new Map<string, Drivers>();
  selectedDriver?: Drivers;

  constructor() {
    makeAutoObservable(this);
  }

  get drivers() {
    const driverList = Array.from(this.driverRegistry.values());
    if (store.delegationStore.delegation) {
      return driverList.filter(
        (driver) => driver.delegationId === store.delegationStore.delegation?.id
      );
    } else return driverList;
  }

  selectDriver = (driver: Drivers) => {
    this.selectedDriver = driver;
  };

  getDrivers = async () => {
    const result = await agent.Drivers.list();
    runInAction(() => {
      result.forEach((driver) => {
        this.driverRegistry.set(driver.id, driver);
      });
    });
  };

  createDriver = async (driver: Drivers) => {
    driver.id = uuid();
    await agent.Drivers.create(driver);
    runInAction(() => {
      this.driverRegistry.set(driver.id, driver);
    });
  };

  updateDriver = async (driver: Drivers) => {
    await agent.Drivers.edit(driver);
    runInAction(() => {
      this.driverRegistry.set(driver.id, driver);
    });
  };

  deleteDriver = async (id: string) => {
    var driverId = await agent.Drivers.delete(id);
    runInAction(() => {
      this.driverRegistry.delete(id);
      if (driverId.replace(/[0-]/g, "")) {
        runInAction(() => {
          const driver = this.driverRegistry.get(driverId);
          if (driver) driver.occupied = true;
        });
      }
    });
  };
}
