import { Link, Outlet, useLocation, useNavigate, useRoutes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  FaSignOutAlt,
  FaHome,
  FaCalendarAlt,
  FaListAlt,
  FaTicketAlt,
  FaHistory,
  FaUserPlus,
  FaHotel,
} from "react-icons/fa";
import { AiFillCar, AiOutlineTwitter } from "react-icons/ai";
import { BiSolidDownArrow } from "react-icons/bi";
import { FaBell, FaUserGear } from "react-icons/fa6";
import { GiHamburgerMenu } from "react-icons/gi";
import "./sidebar.scss";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from "react-pro-sidebar";
import { useStore } from "../../../app/stores/store";
import { useEffect, useState } from "react";
import { sidebarStylization } from "./sidebarStyle";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const { userStore } = useStore();
  const { isAdministrator } = userStore;
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  const toggleSidebar = (): void => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const routes = {
    Home: '/',
    Twitter: '/tweets',
    Events: '/events',
    Agenda: '/agenda',
    Tickets: '/tickets',
    ManageUsers: '/user-list',
    DelegationList: '/delegation-list',
    Media: '/media',
    Ambassadors: '/ambassadors',
    Speakers: '/speakers',
    Audience: '/audience',
    Invitations: '/invitations',
    LoginHistory: '/user-history',
    Hotels: '/hotels',
    UserHotelList: '/user-hotel-list',
    Drivers: '/drivers',
    Notifications: '/notifications',
    NotificationHistory: '/notification-history',
  }

  const sidebarStyles = sidebarStylization(sidebarCollapsed);

  const logout = () => {
    userStore.logout();
    navigate('/login')
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setSidebarCollapsed(true);
      } else {
        setSidebarCollapsed(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const langOptions = [
    {
      value: "en",
      label: (
        <>
          <span className="flag-icon flag-icon-gb flag-a"></span>
          <span className="flag-text">{t("english")}</span>
        </>
      ),
    },
    {
      value: "al",
      label: (
        <>
          <span className="flag-icon flag-icon-al flag-a"></span>
          <span className="flag-text">{t("albanian")}</span>
        </>
      ),
    },
    {
      value: "rs",
      label: (
        <>
          <span className="flag-icon flag-icon-rs flag-a"></span>
          <span className="flag-text">{t("serbian")}</span>
        </>
      ),
    },
  ];

  return (
    <div className="sidebar">
      <ProSidebar collapsed={sidebarCollapsed}>
        <SidebarHeader className="sidebar-header">
          <div className="sidebar-header" style={sidebarStyles}>
            {!sidebarCollapsed && <div className="title">WPS</div>}
            <Menu>
              <MenuItem
                onClick={toggleSidebar}
                icon={<GiHamburgerMenu />}
                className="menu-item"
              />
            </Menu>
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu className="sidebar-main">
            <Select
              className="custom-select"
              options={langOptions}
              isSearchable={false}
              defaultValue={langOptions.find((option) => option.value === "en")}
              value={langOptions.find(
                (option) => option.value === i18n.language
              )}
              onChange={(e: any) => i18n.changeLanguage(e.value)}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => (
                  <BiSolidDownArrow size={9} className="language-arrow" />
                ),
              }}
            />
            <MenuItem className={location.pathname === routes.Home ? 'active-link' : ''}  icon={<FaHome />}>
              <Link to="/">{t("HOME")}</Link>
            </MenuItem>
            <MenuItem className={location.pathname === routes.Twitter ? 'active-link' : ''} icon={<AiOutlineTwitter />}>
              <Link to="tweets">Twitter</Link>
            </MenuItem>
            <MenuItem className={location.pathname === routes.Events ? 'active-link' : ''} icon={<FaCalendarAlt />}>
              <Link to="events">{t("EVENTS")}</Link>
            </MenuItem>

            <MenuItem className={location.pathname === routes.Agenda ? 'active-link' : ''} icon={<FaListAlt />}>
              <Link to="agenda">{t("AGENDA")}</Link>
            </MenuItem>
            <MenuItem className={location.pathname === routes.Tickets ? 'active-link' : ''} icon={<FaTicketAlt />}>
              <Link to="tickets">{t("TICKETS")}</Link>
            </MenuItem>
            {isAdministrator && (
              <>
                <MenuItem className={location.pathname === routes.ManageUsers ? 'active-link' : ''} icon={<FaUserGear />}>
                  <Link to="user-list">{t("MANAGE_USERS")}</Link>
                </MenuItem>
                <MenuItem className={location.pathname === routes.DelegationList ? 'active-link' : ''} icon={<FaUserGear />}>
                  <Link to="delegation-list">Delegation List</Link>
                </MenuItem>
                <MenuItem className={location.pathname === routes.Speakers ? 'active-link' : ''} icon={<FaUserGear />}>
                  <Link to="speakers">Speakers</Link>
                </MenuItem>
                <MenuItem className={location.pathname === routes.Ambassadors ? 'active-link' : ''} icon={<FaUserGear />}>
                  <Link to="ambassadors">Ambassadors</Link>
                </MenuItem>
                <MenuItem className={location.pathname === routes.Media ? 'active-link' : ''} icon={<FaUserGear />}>
                  <Link to="media">Media</Link>
                </MenuItem>
                <MenuItem className={location.pathname === routes.Audience ? 'active-link' : ''} icon={<FaUserGear />}>
                  <Link to="audience">Audience</Link>
                </MenuItem>
                <MenuItem className={location.pathname === routes.Invitations ? 'active-link' : ''} icon={<FaUserPlus />}>
                  <Link to="invitations">{t("INVITATIONS")}</Link>
                </MenuItem>
                <MenuItem className={location.pathname === routes.LoginHistory ? 'active-link' : ''} icon={<FaHistory />}>
                  <Link to="user-history">{t("LOGIN_HISTORY")}</Link>
                </MenuItem>
                <MenuItem className={location.pathname === routes.Hotels ? 'active-link' : ''} icon={<FaHotel />}>
                  <Link to="hotel-list">Hotels</Link>
                </MenuItem>
                <MenuItem className={location.pathname === routes.UserHotelList ? 'active-link' : ''} icon={<FaHotel />}>
                  <Link to="user-hotel-list">User Hotel List</Link>
                </MenuItem>
                <MenuItem className={location.pathname === routes.Drivers ? 'active-link' : ''} icon={<AiFillCar />}>
                  <Link to="drivers">{t("DRIVERS")}</Link>
                </MenuItem>
                <MenuItem className={location.pathname === routes.Notifications ? 'active-link' : ''} icon={<FaBell />}>
                  <Link to="notifications">Notifications</Link>
                </MenuItem>
                <MenuItem className={location.pathname === routes.NotificationHistory ? 'active-link' : ''} icon={<FaBell />}>
                  <Link to="notification-history">Notification History</Link>
                </MenuItem>
              </>
            )}
          </Menu>
        </SidebarContent>

        <SidebarFooter className="sidebar-footer">
          <div className="d-flex align-items-center justify-content-between">
            {!sidebarCollapsed && (
              <Menu>
                <MenuItem className="sidebar-username">
                  {userStore.user?.firstName
                    ? `${userStore.user.firstName}`
                    : "Guest"}
                </MenuItem>
              </Menu>
            )}
            <Menu>
              <MenuItem
                icon={<FaSignOutAlt />}
                className="logout"
                onClick={logout}
              />
            </Menu>
          </div>
        </SidebarFooter>
      </ProSidebar>
      <Outlet />
    </div>
  );
};

export default observer(Sidebar);
