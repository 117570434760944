import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { DelegationList } from "../models/DelegationList";
import { useStore } from "./store";

export default class DelegationStore {
  delegationRegistry = new Map<string, DelegationList>();
  delegation?: DelegationList;

  constructor() {
    makeAutoObservable(this);
  }

  get delegations() {
    return Array.from(this.delegationRegistry.values());
  }

  setDelegation = (delegation: DelegationList | undefined) => {
    this.delegation = delegation;
  };

  sendDelegationInfo = async () => {
    await agent.DelegationApi.sendInfo();
  };

  getDelegationFromUser = async () => {
    const result = await agent.DelegationApi.getDelegationFromUser();
    this.setDelegation(result);
  };

  getDelegations = async () => {
    this.delegationRegistry = new Map<string, DelegationList>();
    const result = await agent.DelegationApi.list();
    runInAction(() => {
      result.forEach((delegation) => {
        this.delegationRegistry.set(delegation.id, delegation);
      });
    });
  };
  getSpeakers = async () => {
    this.delegationRegistry = new Map<string, DelegationList>();
    const result = await agent.DelegationApi.getSpeakers();
    runInAction(() => {
      result.forEach((delegation) => {
        this.delegationRegistry.set(delegation.id, delegation);
      });
    });
  };
  moveDelegationMember = async (userId: string, delegationId: string) => {
    await agent.DelegationApi.moveMemberToAnotherDelegation({
      userId,
      delegationId,
    });
  };
}
