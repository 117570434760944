import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useStore } from "../../app/stores/store";
import "./login.scss";
import { Link } from "react-router-dom";
import { MdAccountCircle } from "react-icons/md";
import {
  AiOutlineMail,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import ResendConfirmEmail from "./ResendConfirmEmail";

function ResetPassword() {
  const { userStore } = useStore();
  const {
    sendResetPasswordRequest,
    sendCodeVerificationForEmailChange,
    changePassword,
  } = userStore;
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [code, setCode] = useState<string>("");

  const [showPassword, setShowPassword] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loadingCodeBtn, setLoadingCodeBtn] = useState(false);

  const [showEmailField, setShowEmailField] = useState(true);
  const [showNewPasswordField, setNewPasswordField] = useState(false);
  const [showCodeField, setShowCodeField] = useState(false);
  const [showSuccessPage, setShowSuccessPage] = useState(false);

  const handleEmailSubmit = (e: any) => {
    e.preventDefault();
    if (!email.trim()) {
      toast.error("Please write a valid email");
      return;
    }
    setLoadingBtn(true);
    sendResetPasswordRequest(email)
      .then(() => {
        toast.success(
          "Please check your email with the code for resetting password"
        );
      })
      .finally(() => {
        setShowCodeField(true);
        setLoadingBtn(false);
      });
  };

  const handleCodeSubmit = () => {
    if (!code.trim()) {
      toast.error("Please write a valid code");
      return;
    }
    setLoadingCodeBtn(true);

    sendCodeVerificationForEmailChange({ code, email })
      .then(() => {
        setNewPasswordField(true);
        setShowCodeField(false);
        setShowEmailField(false);
      })
      .finally(() => {
        setLoadingCodeBtn(false);
      });
  };

  const handlePasswordChange = (e: any) => {
    e.preventDefault();
    if (password.trim().length < 8) {
      toast.error("Password is to short, it should be at least 8 characters");
      return;
    }
    if (!password.trim() || !confirmPassword.trim()) {
      toast.error("You need to write a password");
      return;
    }

    if (password.trim() !== confirmPassword.trim()) {
      toast.error("Password and confirm password doesnt match");
      return;
    }

    setLoadingCodeBtn(true);
    changePassword({ code, email, password })
      .then(() => {
        setShowSuccessPage(true);
        setNewPasswordField(false);
      })
      .catch(() => {
        toast.error("Password too short!");
      })
      .finally(() => {
        setLoadingCodeBtn(false);
      });
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 login">
      <Card className="login-card card-style d-flex justify-content-center align-items-center">
        <Card.Body>
          <Card.Title className="text-center mb-4">
            <MdAccountCircle className="icon-color" size={120} />
          </Card.Title>
          {showEmailField && (
            <>
              <Form onSubmit={handleEmailSubmit} className="screen-1">
                <div className="email">
                  <label>{t("EMAIL_ADDRESS")}</label>
                  <div className="sec-2 d-flex align-items-center gap-1">
                    <AiOutlineMail className="show-hide md hydrated" />
                    <input
                      type="email"
                      value={email}
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="example@gmail.com"
                    />
                  </div>
                </div>
                <button
                  disabled={loadingBtn}
                  type="submit"
                  className="login-button"
                >
                  {loadingBtn ? (
                    <Spinner animation="border" size="sm" variant="light" />
                  ) : showCodeField ? (
                    t("Resend reset password request")
                  ) : (
                    t("Send reset password request")
                  )}
                </button>

                {showCodeField && (
                  <>
                    <div className="email">
                      <label>{t("Code")}</label>
                      <div className="sec-2 d-flex align-items-center gap-1">
                        <input
                          type="code"
                          value={code}
                          name="code"
                          onChange={(e) => setCode(e.target.value)}
                          placeholder="Write code here"
                        />
                      </div>
                    </div>
                    <button
                      disabled={loadingCodeBtn}
                      type="button"
                      className="login-button"
                      onClick={handleCodeSubmit}
                    >
                      {loadingCodeBtn ? (
                        <Spinner animation="border" size="sm" variant="light" />
                      ) : (
                        t("Verify code")
                      )}
                    </button>
                  </>
                )}
              </Form>
            </>
          )}

          {showNewPasswordField && (
            <>
              <Form onSubmit={handlePasswordChange} className="screen-1">
                <div className="password">
                  <label>{t("PASSWORD")}</label>
                  <div className="sec-2 d-flex align-items-center gap-1">
                    <RiLockPasswordLine />
                    <input
                      className=""
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="············"
                      value={password}
                      required
                    />
                    {showPassword ? (
                      <AiOutlineEye
                        className="align-right"
                        onClick={() => setShowPassword(false)}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="align-right"
                        onClick={() => setShowPassword(true)}
                      />
                    )}
                  </div>
                </div>
                <div className="password">
                  <label>{t("Confirm Password")}</label>
                  <div className="sec-2 d-flex align-items-center gap-1">
                    <RiLockPasswordLine />
                    <input
                      className=""
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder="············"
                      value={confirmPassword}
                      required
                    />
                    {showPassword ? (
                      <AiOutlineEye
                        className="align-right"
                        onClick={() => setShowPassword(false)}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="align-right"
                        onClick={() => setShowPassword(true)}
                      />
                    )}
                  </div>
                </div>
                <button
                  disabled={loadingBtn}
                  type="submit"
                  className="login-button"
                >
                  {loadingBtn ? (
                    <Spinner animation="border" size="sm" variant="light" />
                  ) : (
                    t("Change password")
                  )}
                </button>
              </Form>
            </>
          )}
          {showSuccessPage && (
            <>
              <Card className="newFont prevent-select card-shadow login-card-size login-card">
                <Card.Header className="d-flex align-items-center">
                  <i
                    style={{ fontSize: "60px" }}
                    className="fa-regular text-success fa-circle-check pe-3"
                  />
                  <span>Password changed</span>
                </Card.Header>
              </Card>
            </>
          )}

          <div className="mt-3 text-center">
            <Link to="/login">{t("Go back to Login")}</Link>
          </div>
        </Card.Body>
      </Card>
      <ResendConfirmEmail />
    </div>
  );
}

export default observer(ResetPassword);
