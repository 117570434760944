import { useStore } from "../../app/stores/store";
import "./login.scss";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import PageLoader from "../components/loaders/PageLoader";
import AddNewRegistrationCard from "../components/registrations/AddNewRegistrationCard";
import RegistrationCard from "../components/registrations/RegistrationCard";
import { InvitationLinks } from "../../app/models/InvitationLinks";
import { Col, Row } from "react-bootstrap";
import CustomRegisterBtn from "../components/registrations/CustomRegisterBtn";
import { toast } from "react-toastify";
import RegisterDelegationCard from "../components/registrations/RegisterDelegationCard";
import { history } from "../..";
import CustomTextField from "../components/CustomTextField";
import { MeansOfTransport } from "../../utilities/constGroup";

function Register() {
  const { registrationsStore, userStore } = useStore();
  const {
    role,
    setWizard,
    wizard,
    listAllContries,
    users,
    setError,
    delegationDetails,
    resetDelegationInformation,
    setDelegationDetails,
    error,
  } = registrationsStore;
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [registered, setRegistered] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);

  useEffect(() => {
    return () => {
      registrationsStore.resetRegistrations();
    };
  }, []);

  useEffect(() => {
    listAllContries().finally(() => setLoadingInitial(false));
  }, []);

  if (loadingInitial) return <PageLoader />;

  function handleInputChange(e: any) {
    const { name, value } = e.target;
    setDelegationDetails({ ...delegationDetails, [name]: value });
  }

  const handleNext = (): void => {
    let error = "";
    if (wizard === 1) {
      users.forEach((user) => {
        if (
          !user.firstName.trim() ||
          !user.lastName.trim() ||
          !user.email.trim() ||
          user.country === "0" ||
          !user.country ||
          !user.institution.trim() ||
          !user.jobTitle.trim() ||
          (role !== InvitationLinks.Speaker && user.secondRole === "")
        ) {
          error = "Please fill out all the fields!";
        }
        if (!user.photoFile && !error) {
          error = "Please upload a valid Image!";
        }
        if (
          user.secondRole === "3" &&
          (user.securityWeaponAmmo <= 0 ||
            !user.securityWeaponMark.trim() ||
            !user.securityWeaponNumber.trim())
        ) {
          error = "Please fill out all the fields!";
        }
      });
    }
    if (wizard === 2) {
      // if (!delegationDetails.isDriverNeeded) {
      //   if (
      //     delegationDetails.driverList.length <= 0 &&
      //     delegationDetails.meanOfTransport !== "Car"
      //   ) {
      //     error = "Please add at least one driver!";
      //   }
      // }
      // if (delegationDetails.isDriverNeeded) {
      //   if (delegationDetails.driverNrOfCars <= 0) {
      //     error = "Please add Number of Cars needed!";
      //   }
      // }
      if (
        !delegationDetails.arrivalTime ||
        !delegationDetails.departureTime ||
        !delegationDetails.meanOfTransport
        
      ) {
        error = "Please fill out all the fields!";
      }
      if (
        delegationDetails.meanOfTransport === MeansOfTransport.Car &&
        (!delegationDetails.travelCarModel.trim() ||
          !delegationDetails.travelCarPlates.trim() ||
          delegationDetails.travelNrOfCars <= 0)
      ) {
        error = "Please fill out all the fields!";
      }
      if (
        delegationDetails.meanOfTransport === MeansOfTransport.Airplane &&
        (!delegationDetails.arrivingFromCity.trim() ||
          !delegationDetails.arrivingFromCountry.trim() ||
          !delegationDetails.cityDepartingTo.trim() ||
          !delegationDetails.countryDepartingTo.trim())
      ) {
        error = "Please fill out all the fields!";
      }
    }
    if (wizard === 3) {
      if (
        !delegationDetails.isHotelNeeded &&
        delegationDetails.hasAccommodationArrangements === undefined
      ) {
        error = "Please fill accommodation details!";
      }
      if (
        !delegationDetails.isHotelNeeded &&
        delegationDetails.hasAccommodationArrangements
      ) {
        if (!delegationDetails.hotelName) {
          error = "Please add Hotel Name!";
        }
      }
    }
    if (error) {
      setError(true);
      toast.error(error);
    } else {
      setError(false);
      setWizard(wizard + 1);
    }
  };

  const handlePrev = (): void => {
    setWizard(wizard - 1);
  };

  function handleRegisterDelegation(e: any): void {
    e.preventDefault();

    let usersToBeStored;
    if (role === InvitationLinks.Speaker) {
      usersToBeStored = users.map((user) => {
        return {
          ...user,
          secondRole: 0,
        };
      });
    } else {
      usersToBeStored = users;
    }
    if (
      role === InvitationLinks.Delegation &&
      (!delegationDetails.personOfContactFirstName.trim() ||
        !delegationDetails.personOfContactLastName.trim() ||
        !delegationDetails.personOfContactEmail.trim() ||
        !delegationDetails.personOfContactPhoneNumber.trim())
    ) {
      toast.error("Please fill out all the fields!");
      setError(true);
    } else {
      setLoadingBtn(true);
      userStore
        .registerDelegation(usersToBeStored, delegationDetails)
        .then(() => {
          setRegistered(true);
          history.push("/success-register");
        })
        .catch((err) => {
          setLoadingBtn(false);
          history.push("/register-unsuccessful");
        })
        .finally(() => {
          resetDelegationInformation()
          setLoadingBtn(false);
        });
      // setLoadingBtn(false);
      // console.log(delegationDetails);
    }
  }

  return (
    <div
      className={`register main ${
        role === InvitationLinks.Delegation &&
        wizard !== 3 &&
        `register-delegation pt-5`
      }`}
    >
      <div className="register-swiper flex-wrap justify-content-center">
        {wizard === 1 && (!role || role === InvitationLinks.Audience) && (
          <>
            {users.map((user, index) => {
              return <RegistrationCard key={index} userNumberInList={index} />;
            })}
            <AddNewRegistrationCard />
          </>
        )}
        {wizard === 1 &&
          role &&
          [
            InvitationLinks.Ambassador,
            InvitationLinks.Media,
            InvitationLinks.Speaker,
            InvitationLinks.Delegation,
          ].includes(role as InvitationLinks) && (
            <>
              {users.map((user, index) => {
                return (
                  <RegistrationCard key={index} userNumberInList={index} />
                );
              })}
              <AddNewRegistrationCard />
            </>
          )}
        {/* {!role && (
          <Card
            style={{ width: "800px" }}
            className="newFont prevent-select card-shadow login-card-size login-card"
          >
            <Card.Header>
              <p className="login-card-title">
                Registration process is not yet active
              </p>
            </Card.Header>
            <Card.Body className="success-login-card-padding card-font-size">
              <Card.Title className="d-flex align-center align-items-center">
                <h4 className="successful-registration-text">
                  Please check on the official website for further news!
                </h4>
              </Card.Title>
              <div className="pt-3">
                <span className="pe-2">Go to</span>
                <Link to="https://wpsforum-rks.org/">WPS Web</Link>
              </div>
            </Card.Body>
          </Card>
        )} */}
        {wizard !== 1 && <RegisterDelegationCard />}
      </div>
      {(role === InvitationLinks.Delegation ||
        role === InvitationLinks.Speaker) && (
        <div className="register-btns mb-5">
          {wizard === 1 && (
            <div className="delegation-next-name">
              {role === InvitationLinks.Delegation && (
                <div className="delegation-name">
                  <h6 className="ps-1 pb-2">Delegation Name</h6>
                  <CustomTextField
                    value={delegationDetails.delegationName}
                    onChange={handleInputChange}
                    name="delegationName"
                    label="Delegation Name"
                    error={error}
                  />
                </div>
              )}

              <button
                onClick={handleNext}
                className="register-button register-btn-delegation"
              >
                Next
              </button>
            </div>
          )}
          {(([2, 3].includes(wizard) && role === InvitationLinks.Delegation) ||
            ([2].includes(wizard) && role === InvitationLinks.Speaker)) && (
            <Row className="w-100">
              <Col sm={6}>
                <button onClick={handlePrev} className="register-button-back">
                  Previous
                </button>
              </Col>
              <Col sm={6}>
                <button
                  onClick={handleNext}
                  className="register-button register-btn-delegation"
                >
                  Next
                </button>
              </Col>
            </Row>
          )}
          {((wizard === 3 && role === InvitationLinks.Speaker) ||
            wizard === 4) && (
            <Row className="w-100">
              <Col sm={6}>
                <button onClick={handlePrev} className="register-button-back">
                  Previous
                </button>
              </Col>
              <Col sm={6}>
                <CustomRegisterBtn
                  loadingBtn={loadingBtn}
                  registered={registered}
                  onClick={handleRegisterDelegation}
                />
              </Col>
            </Row>
          )}
        </div>
      )}
    </div>
  );
}
export default observer(Register);
