interface Props {
  mTop?: number;
  mBottom?: number;
}

function PageLoader({ mTop = 0, mBottom = 0 }: Props) {
  return (
    <div
      className="spinner"
      style={{ marginTop: mTop, marginBottom: `${mBottom} !important` }}
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}
export default PageLoader;
