import { useEffect, useState } from "react";
import { Col, Form, Row, Button, Container } from "react-bootstrap";
import "./RegistrationCard.module.scss";
import { MdSupervisorAccount } from "react-icons/md";
import { useStore } from "../../../app/stores/store";
import { InvitationLinks } from "../../../app/models/InvitationLinks";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import "./registerCard.scss";
import CustomTextField from "../CustomTextField";
import CustomCheckBox from "../CustomCheckBox";
import CustomSelect from "../CustomSelect";
import {
  SelectArray,
  delegationRolesArray,
} from "../../../app/models/DelegationRoles";
import CustomRegisterBtn from "./CustomRegisterBtn";
import CustomOutlinedInput from "../CustomOutlinedInput";
import { Avatar } from "@mui/material";
import { history } from "../../..";
import { toast } from "react-toastify";

interface RegistrationCardProps {
  userNumberInList: number;
}

function RegistrationCard({ userNumberInList }: RegistrationCardProps) {
  const { userStore, registrationsStore } = useStore();
  const {
    users,
    contryList,
    toggleLeader,
    toggleSpeaker,
    toggleAccreditedMedia,
    role,
    wizard,
    error,
    validateFile,
  } = registrationsStore;
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [registered, setRegistered] = useState(false);
  const { t } = useTranslation();
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  useEffect(() => {
    if (users[userNumberInList].photoFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(users[userNumberInList].photoFile);
    }
    if (role === InvitationLinks.Speaker) {
      // value for forum speaker
      // set on DelegationRoles.ts
      users[userNumberInList].roleId = "0";
    }
    if (role === InvitationLinks.Media) {
      users[userNumberInList].roleId = InvitationLinks.Media;
    }
    if (role === InvitationLinks.Audience || !role ) {
      users[userNumberInList].roleId = InvitationLinks.Audience;
    }
  }, []);

  const roleValueToEnum = Object.fromEntries(
    Object.entries(InvitationLinks).map(([key, value]) => [value, key])
  );

  const validateFields = (): boolean => {
    const birthday = new Date(users[userNumberInList].birthDate as string);
    const minBirthday = new Date("1900-01-01");
    const maxBirthday = new Date("2024-01-01");
    if (users[userNumberInList].country === "0" || !users[userNumberInList].country) {
      toast.error("Invalid country selected");
      return false;
    } else if (!users[userNumberInList].firstName.trim()) {
      toast.error("Please fill name");
      return false;
    } else if (!users[userNumberInList].lastName.trim()) {
      toast.error("Please fill last name");
      return false;
    } else if (!users[userNumberInList].email.trim()) {
      toast.error("Please fill email field");
      return false;
    } else if (birthday < minBirthday || birthday > maxBirthday) {
      toast.error("Please put correct date");
      return false;
    }
    return true;
  };

  function handleRegister(e: any): void {
    e.preventDefault();
    if (!validateFields()) {
      return;
    }
    if (validateFile(users[userNumberInList].photoFile)) {
      setLoadingBtn(true);
      if (role !== "") {
        users[userNumberInList].roleId = role;
      }
      userStore
        .register(users[userNumberInList])
        .then(() => {
          setRegistered(true);
          toast.success("User Registered Successfully!")
        })
        .catch((err) => {
          setLoadingBtn(false);
          toast.error("Registration not successful!")
        })
        .finally(() => {
          setLoadingBtn(false);
        });
    }
  }

  function handleInputChange(e: any) {
    const { name, value } = e.target;
    const userData = {
      ...registrationsStore.users[userNumberInList],
      [name]: value,
    };
    registrationsStore.updateUser(userNumberInList, userData);
  }

  function handleInputChangeNumber(e: any) {
    const { name, value } = e.target;
    let inputValue = parseInt(value, 10);

    if (!isNaN(inputValue) && inputValue >= 1) {
      inputValue = Math.min(inputValue, 10000);

      const userData = {
        ...registrationsStore.users[userNumberInList],
        [name]: inputValue,
      };

      registrationsStore.updateUser(userNumberInList, userData);
    }
  }

  function handleFileChange(e: any) {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      if (!validateFile(selectedFile)) return;

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(selectedFile);
    }

    const { name } = e.target;
    const userData = {
      ...registrationsStore.users[userNumberInList],
      [name]: selectedFile,
    };
    registrationsStore.updateUser(userNumberInList, userData);
  }

  const onRemove = () => {
    registrationsStore.removeUserAt(userNumberInList);
  };

  const roleArray: SelectArray[] = [
    {
      name: "Audience",
      value: InvitationLinks.Audience,
    },
    {
      name: "Media",
      value: InvitationLinks.Media,
    },
  ];
  return (
    <Container fluid className="register-card mt-5">
      <div className="register-card-header">
        {imagePreview ? (
          <Avatar
            alt={users[userNumberInList].firstName}
            src={imagePreview}
            sx={{ width: 100, height: 100 }}
          />
        ) : (
          <MdSupervisorAccount className="icon-color" size={120} />
        )}

        <p>{role ? roleValueToEnum[role] : t("REGISTER")}</p>
        {userNumberInList > 0 && (
          <Button
            variant="danger"
            size="sm"
            onClick={onRemove}
            className="register-close-btn"
          >
            X
          </Button>
        )}
      </div>
      <Form onSubmit={handleRegister} className="register-card-body">
        {role === InvitationLinks.Delegation && (
          <Row>
            <Col sm={6}>
              <CustomCheckBox
                value={users[userNumberInList].isLeader}
                onChange={() => toggleLeader(userNumberInList)}
                label={t("IS_DELEGATION_LEADER")}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={6}>
            <CustomTextField
              value={users[userNumberInList].firstName}
              onChange={handleInputChange}
              name="firstName"
              title="Write your name here"
              label={t("FIRST_NAME")}
              error={error}
            />
          </Col>
          <Col sm={6}>
            <CustomTextField
              value={users[userNumberInList].lastName}
              onChange={handleInputChange}
              name="lastName"
              title="Write your last name here"
              label={t("LAST_NAME")}
              error={error}
            />
          </Col>
        </Row>
        <CustomTextField
          value={users[userNumberInList].birthDate}
          onChange={handleInputChange}
          name="birthDate"
          type="date"
          label="Birth date"
          error={error}
        />
        <CustomTextField
          value={users[userNumberInList].email}
          onChange={handleInputChange}
          name="email"
          type="email"
          label={t("EMAIL")}
          error={error}
        />
        {[InvitationLinks.Delegation, InvitationLinks.Speaker].includes(
          role as InvitationLinks
        ) ? (
          <span className="text-center">
            <em>
              Please be informed that this photo will be featured in the Speaker
              Profiles section of the WPS website.
            </em>
          </span>
        ) : (
          <span className="text-center">
            <em>Upload a personal photo.</em>
          </span>
        )}
        <CustomOutlinedInput
          onChange={handleFileChange}
          name="photoFile"
          type="file"
          accept=".jpg, .jpeg, .png, .webp"
        />

        {[InvitationLinks.Delegation, InvitationLinks.Speaker].includes(
          role as InvitationLinks
        ) && (
          <>
            <span className="text-center">
              <em>
                Please be informed that this text will be featured in the
                Speaker Profiles section of the WPS website.
              </em>
            </span>
            <CustomTextField
              value={users[userNumberInList].shortBio}
              onChange={handleInputChange}
              name="shortBio"
              label={t("short_bio")}
              type="textarea"
              error={error}
              multiline={true}
            />
          </>
        )}

        <>
          {!role && (
            <CustomSelect
              value={users[userNumberInList].roleId}
              name="roleId"
              onChange={handleInputChange}
              options={roleArray}
              label={t("ROLE")}
              error={error}
            />
          )}
        </>
        <CustomSelect
          value={users[userNumberInList].country}
          name="country"
          onChange={handleInputChange}
          required={role !== InvitationLinks.Audience && role !== ""}
          options={contryList}
          label={t("COUNTRY")}
          error={error}
        />
        <CustomTextField
          value={users[userNumberInList].institution}
          onChange={handleInputChange}
          name="institution"
          label={
            users[userNumberInList].roleId === InvitationLinks.Media ||
            role === InvitationLinks.Media
              ? t("MEDIUM_NAME")
              : t("INSTITUTION")
          }
          required={role !== InvitationLinks.Audience && role !== ""}
          error={error}
        />
        {users[userNumberInList].roleId === InvitationLinks.Audience && (
          <CustomTextField
            value={users[userNumberInList].jobTitle}
            onChange={handleInputChange}
            name="jobTitle"
            label={t("JOB_TITLE")}
            error={error}
          />
        )}
        {users[userNumberInList].roleId === InvitationLinks.Media && (
          <CustomCheckBox
            value={users[userNumberInList].isAccredited}
            onChange={() => toggleAccreditedMedia(userNumberInList)}
            label={t("IS_ACCREDITED")}
          />
        )}

        {(role === InvitationLinks.Delegation ||
          role === InvitationLinks.Speaker) && (
          <>
            <CustomTextField
              value={users[userNumberInList].jobTitle}
              onChange={handleInputChange}
              name="jobTitle"
              label={t("JOB_TITLE")}
              error={error}
            />
            {role !== InvitationLinks.Speaker && (
              <CustomSelect
                value={users[userNumberInList].secondRole}
                name="secondRole"
                onChange={handleInputChange}
                options={delegationRolesArray}
                label={"Role"}
                error={error}
              />
            )}
            {users[userNumberInList].secondRole === "3" && (
              <>
                <Row>
                  <Col sm={6}>
                    <CustomTextField
                      value={users[userNumberInList].securityWeaponMark}
                      onChange={handleInputChange}
                      name="securityWeaponMark"
                      label="Weapon Mark"
                      error={error}
                    />
                  </Col>
                  <Col sm={6}>
                    <CustomTextField
                      value={users[userNumberInList].securityWeaponNumber}
                      onChange={handleInputChange}
                      name="securityWeaponNumber"
                      label="Weapon Nr"
                      error={error}
                    />
                  </Col>
                </Row>
                <CustomTextField
                  value={users[userNumberInList].securityWeaponAmmo}
                  onChange={handleInputChangeNumber}
                  name="securityWeaponAmmo"
                  label="Ammo"
                  type="number"
                  error={error}
                />
              </>
            )}
            <Row>
              <Col sm={12}>
                <CustomTextField
                  value={users[userNumberInList].notesForOrganizer}
                  onChange={handleInputChange}
                  name="notesForOrganizer"
                  label={t("NOTES_ORGANIZER")}
                  type="textarea"
                  error={error}
                  multiline={true}
                />
              </Col>
            </Row>
          </>
        )}

        {role !== InvitationLinks.Delegation &&
          role !== InvitationLinks.Speaker && (
            <CustomRegisterBtn
              loadingBtn={loadingBtn}
              registered={registered}
            />
          )}
      </Form>
    </Container>
  );
}
export default observer(RegistrationCard);
