import { useEffect, useState } from "react";
import { Avatar } from "@mui/material";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import MUIDataTable from "mui-datatables";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import PageLoader from "../../components/loaders/PageLoader";

function NotificationHistory() {
  const { notifyStore } = useStore();
  const { notifyHistory } = notifyStore;
  const [loadingIntital, setLoadingInitial] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    notifyStore
      .getNotificationHistory()
      .finally(() => setLoadingInitial(false));
  }, [notifyStore]);

  if (loadingIntital) return <PageLoader />;

  const data = notifyHistory.map((m) => ({
    photo: <Avatar alt={m.user.firstName} src={m.user.photo} />,
    firstName: m.user.firstName,
    lastName: m.user.lastName,
    email: m.user.email,
    body: m.body,
    title: m.title,
    date: dayjs.utc(m.date).local().format("DD-MM-YYYY HH:mm A"),
  }));

  const columns: any = [
    { name: "photo", label: "Photo" },
    { name: "firstName", label: t("FIRST_NAME") },
    { name: "lastName", label: t("LAST_NAME") },
    { name: "email", label: t("EMAIL") },
    { name: "title", label: "Title" },
    { name: "body", label: "Body" },
    { name: "date", label: "Date" },
  ];

  return (
    <div className="main">
      <MUIDataTable
        title={t("USER_LIST")}
        data={data}
        columns={columns}
        options={{
          responsive: "vertical",
          viewColumns: true,
          selectableRows: "none",
          rowsPerPage: 500,
          rowsPerPageOptions: [500, 1000, 1500],
          tableBodyHeight: "auto",
        }}
      />
    </div>
  );
}
export default observer(NotificationHistory);
