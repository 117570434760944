import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useEffect, useState } from "react";
import PageLoader from "../components/loaders/PageLoader";
import { IconButton, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import MUIDataTable from "mui-datatables";
import { AiFillCar } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { FaUser } from "react-icons/fa";
import { history } from "../..";
import { DelegationList as DelegationModel } from "../../app/models/DelegationList";
import { MeansOfTransport } from "../../utilities/constGroup";

function Speakers() {
  const { delegationStore } = useStore();
  const { getSpeakers, delegations, setDelegation } = delegationStore;
  const [loadingInitial, setLoadingInitial] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    getSpeakers().finally(() => setLoadingInitial(false));
  }, [delegationStore]);

  if (loadingInitial) return <PageLoader />;

  const formatPhoneNumber = (number: string | null) => {
    if (!number) {
      return;
    }
    const formattedPhoneNumber = `+${number.substring(0, 3)} ${number.substring(
      3,
      5
    )} ${number.substring(5, 8)} ${number.substring(8)}`;
    return formattedPhoneNumber;
  };

  const handleViewDrivers = (delegation: DelegationModel) => {
    setDelegation(delegation);
    history.push("drivers");
  };

  const handleViewUsers = (delegation: DelegationModel) => {
    setDelegation(delegation);
    history.push("user-list");
  };

  const data = delegations.map((delegation) => ({
    delegationName: delegation.delegationName,
    users: `Nr. of delegation members: ${delegation.usersList?.length}`,
    drivers: `${
      delegation.drivers.length > 0
        ? `Nr. of drivers: ${delegation.drivers.length}`
        : `Nr. of Cars needed: ${delegation.driverNrOfCars}`
    }`,
    hotels: `${
      delegation.hotelName
        ? `Hotel name: ${delegation.hotelName}`
        : `Hotel name defined by Logistical Note`
    }`,
    travelDetails: (
      <div className="paragraph-padding-remove">
        <p>Mean of Transport: {delegation.meanOfTransport}</p>
      </div>
    ),
    transportDetails:
      delegation.meanOfTransport === MeansOfTransport.Car ? (
        <div className="paragraph-padding-remove">
          <p>Nr. of Cars: {delegation.travelNrOfCars}</p>
          <p>Car Plates: {delegation.travelCarPlates}</p>
          <p>Car Model: {delegation.travelCarModel}</p>
        </div>
      ) : (
        <div>
          <p>
            Arriving from: <br /> {delegation.arrivingFromCountry},{" "}
            {delegation.arrivingFromCity}
          </p>
          <p>
            Departing to: <br /> {delegation.countryDepartingTo},{" "}
            {delegation.cityDepartingTo}
          </p>
        </div>
      ),
    transportTimeDetails: (
      <div>
        <p>
          Arrival Time: {dayjs(delegation.arrivalTime).format("DD/MM/YYYY")}
        </p>
        <p>
          Departure Time: {dayjs(delegation.departureTime).format("DD/MM/YYYY")}
        </p>
      </div>
    ),
    personOfContact: (
      <div className="paragraph-padding-remove">
        <p>
          Full Name:{" "}
          {`${delegation.personOfContactFirstName} ${delegation.personOfContactLastName}`}
        </p>
        <p>Email: {delegation.personOfContactEmail}</p>
        <p>Tel: {formatPhoneNumber(delegation.personOfContactPhoneNumber)}</p>
      </div>
    ),
    action: (
      <div className="d-flex">
        <Tooltip
          onClick={() => handleViewUsers(delegation)}
          title={"View Users"}
        >
          <IconButton>
            <FaUser size={20} color="lightblue" />
          </IconButton>
        </Tooltip>
        {delegation.drivers.length > 0 && (
          <Tooltip
            onClick={() => handleViewDrivers(delegation)}
            title={"View Drivers"}
          >
            <IconButton>
              <AiFillCar color="lightblue" />
            </IconButton>
          </Tooltip>
        )}
      </div>
    ),
  }));

  const columns: any = [
    { name: "delegationName", label: "Name" },
    { name: "users", label: "Members" },
    { name: "drivers", label: "Drivers" },
    { name: "hotels", label: "Hotel" },
    { name: "travelDetails", label: "Travel By" },
    { name: "transportDetails", label: "Transport" },
    { name: "transportTimeDetails", label: "Transport details" },
    { name: "personOfContact", label: "Person of Contact" },
    {
      name: "action",
      label: t("ACTION"),
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  return (
    <div className="main">
      <div className="Mui-datatable-user">
        <MUIDataTable
          title={"Speaker only accounts"}
          data={data}
          columns={columns}
          options={{
            responsive: "vertical",
            viewColumns: true,
            selectableRows: "none",
            rowsPerPage: 500,
            rowsPerPageOptions: [500, 1000, 1500],
            tableBodyHeight: "auto",
          }}
        />
      </div>
    </div>
  );
}
export default observer(Speakers);
