import { Navigate, Outlet } from "react-router";
import { useStore } from "../stores/store";

// todo: refactor with admin and logout to be type of single attribute, create another method to check which one to proceed with check
export const ProtectedRoute = ({ redirectPath, admin, logout, login }: any) => {
  const { userStore } = useStore();

  if (
    (login && !userStore.isLoggedIn) ||
    (logout && userStore.isLoggedIn) ||
    (admin && !userStore.isAdministrator)) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};
