import { Modal, Button, Spinner, Form, Row, Col } from "react-bootstrap";
import React, { useState } from "react";
import "../agenda/agendaStyle.scss";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { InvitationList } from "../../../app/models/InvitationList";
import CustomTextField from "../CustomTextField";
import { useStore } from "../../../app/stores/store";
import CustomSelect from "../CustomSelect";
import { SelectArray } from "../../../app/models/DelegationRoles";
import { InvitationLinks } from "../../../app/models/InvitationLinks";

interface Props {
  handleCloseModal: () => void;
}

function CreateInvitation({ handleCloseModal }: Props) {
  const { invitationsStore } = useStore();
  const { createInvitation } = invitationsStore;
  const INVITATION_VALUES: InvitationList = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    roleId: InvitationLinks.Delegation,
  };
  const [invitation, setInvitation] =
    useState<InvitationList>(INVITATION_VALUES);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { t } = useTranslation();

  function handleInputChange(e: any): void {
    const { name, value } = e.target;
    setInvitation({ ...invitation, [name]: value });
  }

  async function handleSubmit(
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> {
    setLoadingBtn(true);
    e.preventDefault();

    await createInvitation(invitation)
      .then(() => {
        setInvitation(INVITATION_VALUES);
        handleCloseModal();
      })
      .finally(() => {
        setLoadingBtn(false);
      });
  }

  const roleArray: SelectArray[] = [
    {
      name: "Audience",
      value: InvitationLinks.Audience,
    },
    {
      name: "Delegation",
      value: InvitationLinks.Delegation,
    },
  ];

  return (
    <div className="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title>Create Invitation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="row-agenda pt-3">
            <Col md={6}>
              <CustomTextField
                label="First Name"
                value={invitation.firstName}
                name="firstName"
                onChange={handleInputChange}
              />
            </Col>
            <Col md={6}>
              <CustomTextField
                label="Last Name"
                value={invitation.lastName}
                name="lastName"
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <CustomSelect
                value={invitation.roleId}
                name="roleId"
                onChange={handleInputChange}
                options={roleArray}
                label={t("ROLE")}
              />
            </Col>
            <Col md={6}>
              <CustomTextField
                label="Email"
                value={invitation.email}
                name="email"
                onChange={handleInputChange}
                type="email"
              />
            </Col>
          </Row>

          <div className="mt-3 gap-2 d-flex">
            <Button disabled={loadingBtn} type="submit" variant="success">
              {loadingBtn ? (
                <Spinner animation="border" size="sm" variant="light" />
              ) : (
                t("CREATE")
              )}
            </Button>
            <Button variant="danger" onClick={handleCloseModal}>
              {t("CLOSE")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </div>
  );
}

export default observer(CreateInvitation);
