import { Card } from "react-bootstrap";
import "./homeStyle.scss";
import { quotes } from "../../assets/data/homedata";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const Home = () => {
  const openWPSWebsite = () => {
    window.open("https://wpsforum-rks.org/", "_blank");
  };

  return (
    <div className="home-main">
      {/* <img src={process.env.PUBLIC_URL + "/wps.png"} alt="" /> */}
      <div className="background-image"></div>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        navigation={true}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper swiperHome"
      >
        {quotes.map((quote, index) => (
          <SwiperSlide className="swiperSlide" key={index}>
            <div className="home-box">
              <Card>
                <Card.Body className="home-box-body">
                  <div className="home-box-image">
                    <img
                      src={process.env.PUBLIC_URL + `/images/${quote.img}`}
                      alt=""
                    />
                  </div>
                  <div className="quote-body-container">
                    <div dangerouslySetInnerHTML={{ __html: quote.body }} />
                    <br />
                    <Card.Text className="text-end">
                      <em>{quote.footer}</em>
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <button onClick={openWPSWebsite} className="button">
        Visit WPS
      </button> */}
    </div>
  );
};

export default Home;
