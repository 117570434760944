import { observer } from "mobx-react-lite";
import { Dialog } from "primereact/dialog";
import { useStore } from "../../../app/stores/store";
import React, { useState } from "react";
import { Button } from "primereact/button";
import { toast } from "react-toastify";

interface Props {
  deleteDialog: boolean;
  hideDeleteDialog: () => void;
  multipleDeleteDialog: boolean;
  hideDeleteMultipleDialog: () => void;
}

function HotelDelete({
  deleteDialog,
  hideDeleteDialog,
  multipleDeleteDialog,
  hideDeleteMultipleDialog,
}: Props) {
  const { hotelStore } = useStore();
  const { hotel, selectedHotels, deleteHotel } = hotelStore;
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);

  const deleteSelectedHotels = async () => {
    setLoadingBtn(true);
    for (const hotel of selectedHotels) {
      await deleteHotel(hotel.id);
    }
    setLoadingBtn(false);
    hideDeleteMultipleDialog();
    toast.success("Hotels Deleted");
  };

  const delHotel = async () => {
    setLoadingBtn(true);
    await deleteHotel(hotel.id)
      .then(() => {
        toast.success("Hotel Deleted");
        hideDeleteDialog();
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingBtn(false));
  };

  const deleteHotelDialogFooter = (multiple: boolean) => {
    return (
      <React.Fragment>
        <Button
          label="No"
          icon="pi pi-times"
          outlined
          onClick={multiple ? hideDeleteMultipleDialog : hideDeleteDialog}
        />
        <Button
          label="Yes"
          disabled={loadingBtn}
          loading={loadingBtn}
          icon="pi pi-check"
          severity="danger"
          onClick={multiple ? deleteSelectedHotels : delHotel}
        />
      </React.Fragment>
    );
  };

  return (
    <>
      <Dialog
        visible={deleteDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteHotelDialogFooter(false)}
        onHide={hideDeleteDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {hotel && (
            <span>
              Are you sure you want to delete <b>{hotel.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={multipleDeleteDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteHotelDialogFooter(true)}
        onHide={hideDeleteMultipleDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {hotel && (
            <span>Are you sure you want to delete the selected hotels?</span>
          )}
        </div>
      </Dialog>
    </>
  );
}
export default observer(HotelDelete);
