import { ReactNode } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiOutlineCheck } from "react-icons/ai";

interface Props {
  loadingBtn: boolean;
  registered: boolean;
  onClick?: any;
}

function CustomRegisterBtn({ loadingBtn, registered, onClick }: Props) {
  const { t } = useTranslation();

  const getRegisterSection = (): ReactNode => {
    if (loadingBtn) {
      return <Spinner animation="border" size="sm" variant="light" />;
    } else if (registered) {
      return (
        <span>
          <AiOutlineCheck /> {t("USER_REGISTERED")}
        </span>
      );
    } else {
      return t("REGISTER");
    }
  };

  return (
    <button
      disabled={loadingBtn || registered}
      type="submit"
      className="register-button"
      onClick={onClick}
    >
      {getRegisterSection()}
    </button>
  );
}

export default CustomRegisterBtn;
