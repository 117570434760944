import { InvitationLinks } from "../app/models/InvitationLinks";
import { Roles } from "../app/models/User";

export const roleIdToEnumMapping: {
  [key: string]: keyof typeof InvitationLinks;
} = {
  [Roles.Audience]: "Audience",
  [Roles.Media]: "Media",
};

const roleToStringMap: { [key: number]: string } = {
  1: "Audience",
  2: "Delegation",
};

const inviteLinkToStringMap: { [key: number]: string } = {
  1: "a2581c17-6698-41e7-85ac-cf3c9a0fca2d",
  2: "d8fd1139-83b1-40c2-8112-ddd8b66e74d5",
};

const inviteLinkToNumberMap: { [key: string]: number } = {
  "a2581c17-6698-41e7-85ac-cf3c9a0fca2d": 1,
  "d8fd1139-83b1-40c2-8112-ddd8b66e74d5": 2,
};

export function mapRoleToString(roleNumber: number): string {
  return roleToStringMap[roleNumber] || "Unknown Role";
}

export function inviteLinkToString(roleNumber: number): string {
  return inviteLinkToStringMap[roleNumber] || "Unknown Role";
}

export function inviteLinkToNumber(roleNumber: string): number {
  return inviteLinkToNumberMap[roleNumber] || 1;
}

export enum MeansOfTransport {
  Car = "Car",
  Airplane = "Airplane",
}
