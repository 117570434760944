import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { observer } from "mobx-react-lite";
import CryptoJS from "crypto-js";
import {
  Hotel,
  Room,
  RoomOccupation,
  RoomType,
} from "../../../app/models/Hotel";
import { useStore } from "../../../app/stores/store";
import { Dropdown } from "primereact/dropdown";
import PageLoader from "../../components/loaders/PageLoader";
import { emptyHotel } from "../../../app/stores/hotelStore";
import HotelSave from "./HotelSave";
import HotelDelete from "./HotelDelete";
import { emptyRoomCreate } from "../../../app/stores/roomStore";
import RoomSave from "./RoomSave";
import RoomDelete from "./RoomDelete";
import { Tag } from "primereact/tag";
import KeySettingModal from "../../components/KeySettingModal";

function HotelList() {
  const { hotelStore, roomStore } = useStore();
  const { hotels, setHotel, selectedHotels, setSelectedHotels } = hotelStore;
  const {
    rooms,
    setSelectedRooms,
    selectedRooms,
    setRoomHotelId,
    setRoomCreate,
    hotelId,
    setRoomOccupation,
    setRoom,
    setDelegationMemberId,
    setInitialRoomOccupation,
  } = roomStore;
  const [dialog, setDialog] = useState<boolean>(false);
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [multipleDeleteDialog, setMultipleDeleteDialog] =
    useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const dt = useRef<DataTable<any[]>>(null);
  const [key, setKey] = useState<string>("");

  useEffect(() => {
    hotelStore.getHotels().finally(() => setLoadingInitial(false));
  }, [hotelStore]);

  if (loadingInitial) return <PageLoader />;

  const openNew = () => {
    if (hotelId) {
      setRoomCreate(emptyRoomCreate);
      setRoomOccupation(undefined);
    } else {
      setHotel(emptyHotel);
    }
    setDialog(true);
  };

  const editHotel = (data: any) => {
    if (hotelId) {
      const obj: RoomOccupation = {
        id: data.id,
        occupied: data.occupied,
        delegationId: data.delegationId,
      };
      setDelegationMemberId(obj.delegationId);
      setInitialRoomOccupation(obj);
      setRoomOccupation(obj);
    } else {
      setHotel({ ...data });
    }
    setDialog(true);
  };

  const confirmDelete = (hotel: Hotel) => {
    if (hotelId) {
      const room = rooms?.find((r) => r.id === hotel.id);
      if (room) setRoom(room);
    } else {
      setHotel(hotel);
    }

    setDeleteDialog(true);
  };

  const hideDeleteDialog = () => {
    setDeleteDialog(false);
    setHotel(emptyHotel);
    setRoomCreate(emptyRoomCreate);
  };

  const hideDeleteMultipleDialog = () => {
    setMultipleDeleteDialog(false);
    setSelectedHotels([]);
    setSelectedRooms([]);
  };

  const statusBodyTemplate = (rowData: Room) => {
    const severity = getSeverity(rowData);
    return (
      <Tag severity={severity}>
        {severity === "success" ? "Non-VIP" : "VIP"}
      </Tag>
    );
  };

  const getSeverity = (room: Room) => {
    switch (room.type) {
      case RoomType.NonVip:
        return "success";

      case RoomType.Vip:
        return "warning";
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label={`New ${hotelId ? "Room" : "Hotel"}`}
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          onClick={() => setMultipleDeleteDialog(true)}
          disabled={
            hotelId
              ? !selectedRooms || !selectedRooms.length
              : !selectedHotels || !selectedHotels.length
          }
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    const options = hotels.map((hotel) => ({
      label: decryptInformation(hotel.name),
      value: hotel.id,
    }));

    return (
      <Dropdown
        value={hotelId}
        onChange={(e) => setRoomHotelId(e.value)}
        options={options}
        optionLabel="label"
        showClear
        placeholder="Select a Hotel"
        className="w-full md:w-14rem"
      />
    );
  };

  const decryptInformation = (text: string): string => {
    try {
      if (!text) {
        return "";
      }
      const decrypted = CryptoJS.AES.decrypt(text, key).toString(
        CryptoJS.enc.Utf8
      );
      return decrypted;
    } catch (e) {
      return "";
    }
  };

  const actionBodyTemplate = (rowData: Hotel) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          outlined
          className="mr-2"
          onClick={() => editHotel(rowData)}
        />
        <Button
          icon="pi pi-trash"
          outlined
          severity="danger"
          onClick={() => confirmDelete(rowData)}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between mt-2 mb-2">
      <h4 className="m-0">Manage {hotelId ? "Rooms" : "Hotels"}</h4>
    </div>
  );

  const renderColumns = () => {
    if (hotelId) {
      return [
        <Column
          key="nr"
          field="nr"
          header="Number"
          sortable
          body={(rowData) => {
            const roomNumber = decryptInformation(rowData.nr);
            return <span>{roomNumber}</span>;
          }}
          style={{ minWidth: "16rem" }}
        ></Column>,
        <Column
          key="type"
          field="type"
          header="Type"
          body={statusBodyTemplate}
          sortable
          filter={false}
          style={{ minWidth: "16rem" }}
        ></Column>,
        <Column
          key="occupied"
          field="occupied"
          header="Occupied"
          sortable
          filter={false}
          style={{ minWidth: "16rem" }}
        ></Column>,
        <Column
          key="delegationName"
          header="Delegation Name"
          body={(rowData) => {
            return rowData.delegationName
              ? rowData.delegationName
              : "No Member";
          }}
          sortable
          filter={false}
          style={{ minWidth: "16rem" }}
        ></Column>,
      ];
    } else {
      return [
        <Column
          key="name"
          field="name"
          header="Name"
          sortable
          body={(rowData) => {
            const hotelName = decryptInformation(rowData.name);
            return <span>{hotelName}</span>;
          }}
          style={{ minWidth: "16rem" }}
        ></Column>,
        <Column
          key="rooms"
          header="Total Rooms"
          body={(rowData) => {
            const totalRooms = rowData.rooms ? rowData.rooms.length : 0;
            return <span>{totalRooms}</span>;
          }}
          sortable
          style={{ minWidth: "10rem" }}
        ></Column>,
      ];
    }
  };

  if (key) {
    return (
      <div className="main hotel-list">
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={hotelId ? rooms : hotels}
            selection={hotelId ? selectedRooms : selectedHotels}
            onSelectionChange={(e: any) => {
              if (Array.isArray(e.value)) {
                hotelId
                  ? setSelectedRooms(e.value)
                  : setSelectedHotels(e.value);
              }
            }}
            dataKey="id"
            selectionMode="multiple"
            paginator
            rows={500}
            rowsPerPageOptions={[500, 1000, 1500]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            header={header}
          >
            <Column selectionMode="multiple" exportable={false}></Column>
            {renderColumns()}

            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ minWidth: "2rem" }}
            ></Column>
          </DataTable>
        </div>

        {/* Create & Edit */}
        {hotelId ? (
          <RoomSave
            dialog={dialog}
            hideDialog={() => setDialog(false)}
            decryptor={key}
          />
        ) : (
          <HotelSave
            dialog={dialog}
            hideDialog={() => setDialog(false)}
            decryptor={key}
          />
        )}
        {/* Delete & Multiple Delete */}
        {hotelId ? (
          <RoomDelete
            deleteDialog={deleteDialog}
            hideDeleteDialog={hideDeleteDialog}
            multipleDeleteDialog={multipleDeleteDialog}
            hideDeleteMultipleDialog={hideDeleteMultipleDialog}
          />
        ) : (
          <HotelDelete
            deleteDialog={deleteDialog}
            hideDeleteDialog={hideDeleteDialog}
            multipleDeleteDialog={multipleDeleteDialog}
            hideDeleteMultipleDialog={hideDeleteMultipleDialog}
          />
        )}
      </div>
    );
  }

  return (
    <KeySettingModal
      show={!key}
      onClick={function (value) {
        setKey(value);
      }}
    />
  );
}

export default observer(HotelList);
