import { makeAutoObservable, runInAction } from "mobx";
import { store } from "./store";
import { Room, RoomCreate, RoomOccupation, RoomType } from "../models/Hotel";
import { v4 as uuid } from "uuid";
import agent from "../api/agent";

export const emptyRoomCreate: RoomCreate = {
  id: "",
  rooms: [],
  type: RoomType.NonVip,
  occupied: false,
  roomNr: 0,
  hotelId: "",
};

export const emptyRoom: Room = {
  id: "",
  nr: 0,
  type: RoomType.NonVip,
  occupied: false,
  hotelId: "",
};

export default class RoomStore {
  hotelId: string | null = null;
  roomCreate: RoomCreate = emptyRoomCreate;
  room: Room = emptyRoom;
  selectedRooms: Room[] = [];
  roomOccupation?: RoomOccupation;
  selectedDelegationRoom?: Room;
  delegationMemberId = "";
  initialRoomOccupation?: RoomOccupation;

  constructor() {
    makeAutoObservable(this);
  }

  get rooms() {
    if (this.hotelId) {
      const rooms = store.hotelStore.hotelRegistry.get(this.hotelId)?.rooms;
      return rooms ? rooms : undefined;
    } else {
      return undefined;
    }
  }

  setRoomMember = (state: Room | undefined) => {
    this.selectedDelegationRoom = state;
  };

  setDelegationMemberId = (state: string) => {
    this.delegationMemberId = state;
  };

  setInitialRoomOccupation = (state: RoomOccupation | undefined) => {
    this.initialRoomOccupation = state;
  };

  setRoom = (state: Room) => {
    this.room = state;
  };

  setRoomCreate = (state: RoomCreate) => {
    this.roomCreate = state;
  };

  setSelectedRooms = (state: Room[]) => {
    this.selectedRooms = state;
  };

  setRoomHotelId = (id: string) => {
    this.hotelId = id;
  };

  setRoomOccupation = (state: RoomOccupation | undefined) => {
    this.roomOccupation = state;
  };

  createRoom = async (room: RoomCreate) => {
    room.hotelId = this.hotelId;
    const result = await agent.HotelApi.createRoom(room);
    runInAction(() => {
      store.hotelStore.hotelRegistry.set(result.id, result);
    });
  };

  editOccupation = async (room: RoomOccupation) => {
    const result = await agent.HotelApi.occupyRoom(room);
    runInAction(() => {
      store.hotelStore.hotelRegistry.set(result.id, result);
    });
  };

  deleteRoom = async (id: string) => {
    const roomId = await agent.HotelApi.deleteRoom(id);
    runInAction(() => {
      const hotel = store.hotelStore.hotelRegistry.get(this.hotelId!);
      if (hotel) {
        if (roomId.replace(/[0-]/g, "")) {
          runInAction(() => {
            const room = hotel.rooms?.find((r) => r.id === roomId);
            if (room) room.occupied = true;
          });
        }
        hotel.rooms = hotel.rooms?.filter((room) => room.id !== id);
      }
    });
  };
}
