import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import {
  NotificationBody,
  NotificationHistory,
  NotificationList,
  SendNotification,
} from "../models/Notification";

export default class NotifyStore {
  notifyRegistry = new Map<string, NotificationList>();
  notifyHistoryRegistry = new Map<string, NotificationHistory>();
  selectedNotifications: NotificationList[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get notifyList() {
    return Array.from(this.notifyRegistry.values());
  }

  get notifyHistory() {
    return Array.from(this.notifyHistoryRegistry.values());
  }

  setSelectedNotifications = (state: NotificationList[]) => {
    this.selectedNotifications = state;
  };

  getNotificationList = async () => {
    const result = await agent.FirebaseApi.list();
    runInAction(() => {
      result.forEach((notification) => {
        this.notifyRegistry.set(notification.id, notification);
      });
    });
  };

  getNotificationHistory = async () => {
    const result = await agent.FirebaseApi.listHistory();
    runInAction(() => {
      result.forEach((notification) => {
        this.notifyHistoryRegistry.set(notification.id, notification);
      });
    });
  };

  sendNotification = async (notification: NotificationBody) => {
    const sendNotification: SendNotification = {
      title: notification.title,
      body: notification.body,
      userIds: this.selectedNotifications.map((x) => x.id),
    };
    await agent.FirebaseApi.send(sendNotification);
    runInAction(() => {
      this.selectedNotifications = [];
    });
  };
}
