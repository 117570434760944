import { Route, Routes } from "react-router-dom";
import Sidebar from "./design/components/sidebar/Sidebar";
import { ProtectedRoute } from "./app/common/ProtectedRoute";
import Register from "./design/userAuth/Register";
import VerifyEmail from "./design/userAuth/VerifyEmail";
import Invitations from "./design/admin/Invitations";
import Home from "./design/home/Home";
import Login from "./design/userAuth/Login";
import Agenda from "./design/components/agenda/Agenda";
import UserList from "./design/admin/UserList";
import Tickets from "./design/tickets/Tickets";
import Events from "./design/events/Events";
import UserLoginHistory from "./design/admin/UserLoginHistory";
import HotelList from "./design/admin/hotels/HotelList";
import Drivers from "./design/components/drivers/Drivers";
import Tweets from "./design/components/tweets/Tweets";
import UserHotelList from "./design/admin/hotels/UserHotelList";
import Notifications from "./design/admin/notifications/Notifications";
import NotificationHistory from "./design/admin/notifications/NotificationHistory";
import DelegationList from "./design/admin/DelegationList";
import Ambassadors from "./design/admin/Ambassadors";
import Media from "./design/admin/Media";
import Audience from "./design/admin/Audience";
import Speakers from "./design/admin/Speakers";
import SuccessRegister from "./design/userAuth/SuccessRegister";
import UnsuccessfulRegister from "./design/userAuth/UnsuccessfulRegister";
import ResetPassword from "./design/userAuth/ResetPassword";
import EventsMobile from "./design/events/Events-mobile";

const Main = () => {
  return (
    <Routes>
      <Route path="mobile/">
        <Route path="events" element={<EventsMobile />} />
      </Route>
      <Route path="/" element={<Sidebar />}>
        <Route path="events" element={<Events />} />
      </Route>

      <Route element={<ProtectedRoute login redirectPath="/login" />}>
        <Route path="/" element={<Sidebar />}>
          <Route index element={<Home />} />
          <Route path="agenda" element={<Agenda />} />
          <Route path="tickets" element={<Tickets />} />
          <Route path="tweets" element={<Tweets />} />
          <Route element={<ProtectedRoute admin redirectPath="/" />}>
            <Route path="/invitations" element={<Invitations />} />
            <Route path="/user-list" element={<UserList />} />
            <Route path="/user-history" element={<UserLoginHistory />} />
            <Route path="/hotel-list" element={<HotelList />} />
            <Route path="/drivers" element={<Drivers />} />
            <Route path="/user-hotel-list" element={<UserHotelList />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/delegation-list" element={<DelegationList />} />
            <Route path="/speakers" element={<Speakers />} />
            <Route path="/ambassadors" element={<Ambassadors />} />
            <Route path="/audience" element={<Audience />} />
            <Route path="/media" element={<Media />} />
            <Route
              path="/notification-history"
              element={<NotificationHistory />}
            />
          </Route>
        </Route>
      </Route>

      <Route element={<ProtectedRoute logout redirectPath="/" />}>
        <Route path="login" element={<Login />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="register" element={<Register />} />
        <Route path="success-register" element={<SuccessRegister />} />
        <Route
          path="register-unsuccessful"
          element={<UnsuccessfulRegister />}
        />
        <Route path="account/verifyEmail" element={<VerifyEmail />} />
      </Route>
    </Routes>
  );
};

export default Main;
