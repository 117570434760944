import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useStore } from "./app/stores/store";
import Main from "./Main";
import { observer } from "mobx-react-lite";
import PageLoader from "./design/components/loaders/PageLoader";

function App() {
  const { commonStore, userStore } = useStore();

  useEffect(() => {
    if (commonStore.token) {
      userStore.getUser().finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded();
    }
  }, [commonStore, userStore, commonStore.token]);

  if (!commonStore.appLoaded) return <PageLoader />;

  return (
    <>
      <ToastContainer
        position="bottom-right"
        theme="light"
        closeOnClick
        hideProgressBar
      />
      <Main />
    </>
  );
}

export default observer(App);
