import { Autocomplete, TextField } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useStore } from "../../app/stores/store";
import { useEffect, useState } from "react";
import {
  DelegationList,
  DelegationUser,
} from "../../app/models/DelegationList";
import { toast } from "react-toastify";

interface Props {
  show: boolean;
  delegation: DelegationList;
  hide: () => void;
}

function AddMemberToDelegationModal({ show, hide, delegation }: Props) {
  const { t } = useTranslation();

  const { delegationStore, manageUsersStore } = useStore();
  const { delegations, moveDelegationMember } = delegationStore;

  const [allMembers, setAllMembers] = useState<DelegationUser[]>([]);
  const [memberSelected, setMemberSelected] = useState<DelegationUser>();
  const [memberIdSelected, setMemberIdSelected] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const onButtonClick = async () => {
    setLoading(true);
    if (memberIdSelected) {
      await moveDelegationMember(memberIdSelected, delegation.id);
      toast.success("User updated")
      setMemberIdSelected(undefined)
      setMemberSelected(undefined)
      manageUsersStore.loadAllUsers()
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!allMembers.length && delegations.length) {
      const tempUserList = [];
      for (const delegation of delegations) {
        for (const user of delegation.usersList) {
          tempUserList.push(user);
        }
      }
      setAllMembers(tempUserList);
    }
  }, [allMembers, delegations]);

  useEffect(() => {
    if (!memberIdSelected) {
      setMemberSelected(undefined);
    } else {
      const member = allMembers.find(
        (member) => member.id === memberIdSelected
      );

      setMemberSelected(member);
    }
  }, [memberIdSelected]);

  return (
    <Modal centered show={show} onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title>{t("USER_LIST")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>
          Add to: <br /> {delegation.delegationName}
        </h4>
        <Autocomplete
          disablePortal
          options={allMembers.map((member) => {
            return {
              label: `${member.firstName} ${member.lastName} - ${member.email}`,
              value: member.id,
            };
          })}
          onChange={(e, newValue: { label: string; value: string } | null) => {
            if (newValue) {
              setMemberIdSelected(newValue.value);
            } else {
              setMemberIdSelected(undefined);
            }
          }}
          value={
            memberSelected
              ? {
                  label: `${memberSelected.firstName} ${memberSelected.lastName} - ${memberSelected.email}`,
                  value: memberSelected.id,
                }
              : undefined
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Other delegation members"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hide}>
          {t("CLOSE")}
        </Button>
        <Button disabled={loading} variant="success" onClick={onButtonClick}>
          Add member to this delegation
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default AddMemberToDelegationModal;
