import { Button, Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props {
  show: boolean;
  hide: () => void;
  loading: boolean;
  btnText: string;
  modalBody: string;
  onClick: () => Promise<void>;
}

function TicketConfirmModal({
  show,
  hide,
  loading,
  btnText,
  modalBody,
  onClick,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal centered show={show} onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title>{t("USER_LIST")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalBody}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hide}>
          {t("CLOSE")}
        </Button>
        <Button disabled={loading} variant="danger" onClick={onClick}>
          {loading ? (
            <Spinner animation="border" size="sm" variant="light" />
          ) : (
            btnText
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default TicketConfirmModal;
