import { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import CreateAgenda from "./CreateAgenda";
import MUIDataTable from "mui-datatables";
import { useStore } from "../../../app/stores/store";
import ComponentLoader from "../loaders/ComponentLoader";
import { observer } from "mobx-react-lite";
import dayjs from "dayjs";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EditAgenda from "./EditAgenda";
import { useTranslation } from "react-i18next";
import { Halls } from "../../../app/models/Agenda";

const Agenda = () => {
  const { agendaStore, userStore } = useStore();
  const MODAL_VALUES = { create: false, edit: false, dialog: false };
  const [show, setShow] = useState(MODAL_VALUES);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [selectedAgenda, setSelectedAgenda] = useState({
    id: "",
    speaker: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    agendaStore.getAgenda();
  }, [agendaStore]);

  if (agendaStore.loadingAgenda) return <ComponentLoader mTop={50} />;

  const handleShow = (action: "create" | "edit" | "dialog", state: boolean) => {
    setShow({ ...show, [action]: state });
  };

  const handleAgree = () => {
    setLoadingBtn(true);
    agendaStore
      .deleteAgenda(selectedAgenda.id)
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        handleShow("dialog", false);
        setLoadingBtn(false);
      });
  };

  function handleOpenEdit(agenda: any) {
    handleShow("edit", true);
    agendaStore.selectAgend(agenda);
  }

  const AGEND_DATA = agendaStore.agendas.map((x) => ({
    // nrRendor: x.nrRendor,
    fromTime: dayjs(x.fromTime).format("DD-MM-YYYY HH:mm"),
    endingTime: dayjs(x.endingTime).format("DD-MM-YYYY HH:mm"),
    speaker: x.speaker,
    description: x.description,
    hallNumber: Halls.find(hall => hall.value === x.hallNumber)?.label || "",
    action: (
      <div className="d-flex">
        <Tooltip
          onClick={() => {
            setSelectedAgenda({ id: x.id, speaker: x.speaker });
            handleShow("dialog", true);
          }}
          title="Delete"
        >
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          onClick={() => {
            handleOpenEdit(x);
          }}
          title="Edit"
        >
          <IconButton>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </div>
    ),
  }));

  const AGEND_COLUMNS = [
    // { name: "nrRendor", label: t("SERIAL_NUMBER") },
    { name: "fromTime", label: t("STARTING_TIME") },
    { name: "endingTime", label: t("ENDING_TIME") },
    { name: "speaker", label: t("SPEAKER") },
    { name: "description", label: t("DESCRIPTION") },
    { name: "hallNumber", label: t("HALL_NUMBER") },
    {
      name: "action",
      label: t("ACTION"),
      options: {
        filter: false,
        sort: false,
        display: userStore.isAdministrator,
        setCellProps: () => ({
          style: { width: "100px" },
        }),
      },
    },
  ];

  return (
    <>
      <div className="main">
        <MUIDataTable
          title={t("AGENDA_LIST")}
          data={AGEND_DATA}
          columns={AGEND_COLUMNS}
          options={{
            responsive: "vertical",
            viewColumns: true,
            selectableRows: "none",
            rowsPerPage: 500,
            rowsPerPageOptions: [500, 1000, 1500],
            tableBodyHeight: "auto",
            customToolbar: () => (
              <>
                {userStore.isAdministrator && (
                  <Tooltip
                    onClick={() => handleShow("create", true)}
                    title={t("ADD")}
                  >
                    <IconButton>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            ),
          }}
        />
      </div>

      <Dialog open={show.dialog} onClose={() => handleShow("dialog", false)}>
        <DialogContent>
          {t("CONFIRM_DELETE_2")} "{selectedAgenda.speaker}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleShow("dialog", false)}>
            {t("CANCEL")}
          </Button>
          <Button disabled={loadingBtn} variant="danger" onClick={handleAgree}>
            {loadingBtn ? (
              <Spinner animation="border" size="sm" variant="light" />
            ) : (
              t("AGREE")
            )}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Create */}
      <Modal
        onHide={() => handleShow("create", false)}
        show={show.create}
        size="lg"
        centered
      >
        <CreateAgenda handleCloseAgenda={() => handleShow("create", false)} />
      </Modal>
      {/* Edit */}
      <Modal
        onHide={() => handleShow("edit", false)}
        show={show.edit}
        size="lg"
        centered
      >
        <EditAgenda handleCloseEditAgenda={() => handleShow("edit", false)} />
      </Modal>
    </>
  );
};
export default observer(Agenda);
