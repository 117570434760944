import { Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { Link, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";

function AddNewRegistrationCard() {
  const { registrationsStore } = useStore();
  const { t } = useTranslation();
  const location = useLocation();
  const params: URLSearchParams = new URLSearchParams(location.search);
  const role: string = params.get("roleId") || "";

  return (
    <Container fluid className="new-registration-card register-card  my-5">
      <Button
        onClick={() => registrationsStore.addUserToRegistrationList(role)}
      >
        {t("ADD_NEW_REGISTRATION")}
      </Button>
      <div className="text-center">
        {t("ALREADY_HAVE_ACCOUNT")} <Link to="/login">{t("LOGIN")}</Link>
      </div>
    </Container>
  );
}
export default observer(AddNewRegistrationCard);
