import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { v4 as uuid } from "uuid";
import { Hotel, UserHotel } from "../models/Hotel";
import { cloneDeep } from "lodash";

export const emptyHotel: Hotel = {
  id: "",
  name: "",
};

export default class HotelStore {
  hotelRegistry = new Map<string, Hotel>();
  userHotelRegistry = new Map<string, UserHotel>();
  hotel: Hotel = emptyHotel;
  selectedHotels: Hotel[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get hotels() {
    return Array.from(this.hotelRegistry.values());
  }

  get userHotelList() {
    return Array.from(this.userHotelRegistry.values());
  }

  setHotel = (state: Hotel) => {
    this.hotel = state;
  };

  setSelectedHotels = (state: Hotel[]) => {
    this.selectedHotels = state;
  };

  getHotels = async () => {
    const result = await agent.HotelApi.list();
    runInAction(() => {
      result.forEach((hotel) => {
        this.hotelRegistry.set(hotel.id, hotel);
      });
    });
  };

  getUserHotels = async () => {
    const result = await agent.HotelApi.userHotel();
    runInAction(() => {
      result.forEach((userHotel) => {
        this.userHotelRegistry.set(userHotel.delegationId, userHotel);
      });
    });
  };

  createHotel = async (hotel: Hotel) => {
    const newHotel = cloneDeep(hotel);
    newHotel.id = uuid();
    await agent.HotelApi.create(newHotel);
    runInAction(() => {
      this.hotelRegistry.set(newHotel.id, newHotel);
    });
  };

  updateHotel = async (hotel: Hotel) => {
    await agent.HotelApi.edit(hotel);
    runInAction(() => {
      this.hotelRegistry.set(hotel.id, hotel);
    });
  };

  deleteHotel = async (id: string) => {
    await agent.HotelApi.delete(id);
    runInAction(() => {
      this.hotelRegistry.delete(id);
    });
  };
}
