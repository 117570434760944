import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import PageLoader from "../components/loaders/PageLoader";
import MUIDataTable from "mui-datatables";
import { observer } from "mobx-react-lite";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

function UserLoginHistory() {
  const { loginHistoryStore } = useStore();
  const { loginHistory } = loginHistoryStore;
  const [loadingIntital, setLoadingInitial] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    loginHistoryStore
      .loginHistoryList()
      .finally(() => setLoadingInitial(false));
  }, [loginHistoryStore]);

  if (loadingIntital) return <PageLoader />;

  const LOGIN_DATA = loginHistory.map((x) => {
    const formattedLoginTime = dayjs
      .utc(x.loginTime)
      .local()
      .format("DD MMMM YYYY, HH:mm");
    return {
      email: x.userEmail,
      loginTime: formattedLoginTime,
    };
  });

  const LOGIN_COLUMNS = [
    { name: "email", label: t("EMAIL") },
    { name: "loginTime", label: t("LOGGED_IN_AT") },
  ];

  return (
    <div className="main">
      <MUIDataTable
        title={t("USER_LOGIN_ACTIVITY")}
        data={LOGIN_DATA}
        columns={LOGIN_COLUMNS}
        options={{
          responsive: "vertical",
          viewColumns: true,
          selectableRows: "none",
          rowsPerPage: 500,
          rowsPerPageOptions: [500, 1000, 1500],
          tableBodyHeight: "auto",
          sortOrder: {
            name: "loginTime",
            direction: "desc",
          },
        }}
      />
    </div>
  );
}
export default observer(UserLoginHistory);
