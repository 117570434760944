import React, { useEffect, useState } from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";

const Tweets = () => {

  return (
    <div className="main">
      <div className="column">
        <h2>Presidenca_RKS Tweets</h2>
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName="Presidenca_RKS"
        />
      </div>
      <div className="column">
        <h2>#WPSFORUM Tweets</h2>
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName="WPSFORUM"
        />
      </div>
    </div>
  );
};

export default Tweets;
