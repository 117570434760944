import { useState } from "react";
import { useStore } from "../../app/stores/store";
import { useTranslation } from "react-i18next";
import agent from "../../app/api/agent";
import { toast } from "react-toastify";
import { Button, Card, Modal } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import "./login.scss"

const ResendConfirmEmail = () => {
  const { userStore } = useStore();
  const {
    registerEmail,
    toggleResendConfirmEmail,
    resendConfirmEmail,
    toggleConfirmEmail,
    confirmEmail,
  } = userStore;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  function handleConfirmEmailResend() {
    setLoading(true);
    try {
      agent.Account.resendEmailConfirm(registerEmail);
      toast.success(t("verification_email_sent"));
      toggleResendConfirmEmail(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <>
      <Modal
        show={confirmEmail}
        onHide={() => toggleConfirmEmail(false)}
        size="lg"
        className="fit-modal hehe"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Card className="newFont prevent-select">
          <Modal.Header>
            <p className="login-card-title">{t("reg_status")}!</p>
          </Modal.Header>
          <Modal.Body className="card-padding card-font-size">
            <div className="d-flex align-center small-center">
              <i
                style={{ fontSize: "60px" }}
                className="fa-regular text-success fa-circle-check pe-3"
              />
              <h4 className="successful-registration-text">
                {t("success_register")}
              </h4>
            </div>
            <div className="resend-description small-center">
              <p className="pt-4">{t("success_register_desc1")}</p>
              <p>{t("success_register_desc2")}</p>
            </div>
            <div className="pt-4">
              <button
                className="resend-verification-button"
                disabled={loading}
                onClick={handleConfirmEmailResend}
              >
                {t("resend_verification")}
              </button>
            </div>
          </Modal.Body>
        </Card>
      </Modal>

      <Modal
        show={resendConfirmEmail}
        onHide={() => toggleResendConfirmEmail(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="fit-modal"
        centered
        scrollable
      >
        <Card className="newFont prevent-select">
          <Modal.Header>
            <p className="login-card-title">{t("reg_status")}!</p>
          </Modal.Header>
          <Modal.Body className="card-padding card-font-size small-center">
            <div className="d-flex align-center">
              <i
                style={{ fontSize: "60px" }}
                className="fa-regular text-success fa-circle-check pe-3"
              />
              <h4 className="successful-registration-text pt-2">
                {t("resend_email_verification")}
              </h4>
            </div>

            <div className="resend-description">
              <p className="pt-4">{t("resend_email_verification_desc1")}</p>
              <p>{t("resend_email_verification_desc2")}</p>
            </div>
            <div className="ms-4">
              <button
                className="resend-verification-button"
                disabled={loading}
                onClick={handleConfirmEmailResend}
              >
                {t("resend_verification")}
              </button>
            </div>
          </Modal.Body>
        </Card>
      </Modal>
    </>
  );
};

export default observer(ResendConfirmEmail);
