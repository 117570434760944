import { useState, useEffect, ReactNode } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";
import style from './event.module.scss'

function EventsMobile() {
  const { t } = useTranslation();

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate: Date = new Date("2024-04-15T08:00:00");

    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const difference = targetDate.getTime() - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setCountdown({ days, hours, minutes, seconds });
      } else {
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, []);

  // todo: change with the live event youtube-id
  const [videoId, setVideoId] = useState("M9RibE_4U7U");

  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 1,
    },
  };

  const onReady = (event: any) => {
    // access to player in all event handlers via event.target
    event.target.playVideo();
  };
  const renderStream = () => {
    return (
      <Container>
        <h1 className="text-center">{t("WATCH_LIVE")}</h1>
        <p className="text-center">
          {/* todo: This paragraph should be deleted after the real video comes in! */}
          <em>{t("TEST_VIDEO")}</em>
        </p>
        <YouTube
          className="d-flex justify-content-center"
          iframeClassName="w-full"
          videoId={videoId}
          opts={opts}
          onReady={onReady}
        />
      </Container>
    );
  };

  const renderCountDown = () => {
    return (
      <>
        <h1 className="text-center mb-4">{t("EVENT_COUNTDOWN")}</h1>
        <Row className="justify-content-center">
          <Col md={2}>
            <Card className="text-center">
              <Card.Body>
                <h2>{countdown.days}</h2>
                <p>{t("DAYS")}</p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2}>
            <Card className="text-center">
              <Card.Body>
                <h2>{countdown.hours}</h2>
                <p>{t("HOURS")}</p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2}>
            <Card className="text-center">
              <Card.Body>
                <h2>{countdown.minutes}</h2>
                <p>{t("MINUTES")}</p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2}>
            <Card className="text-center">
              <Card.Body>
                <h2>{countdown.seconds}</h2>
                <p>{t("SECONDS")}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  const render = (): ReactNode => {
    if (
      countdown.days === 0 &&
      countdown.hours === 0 &&
      countdown.minutes === 0 &&
      countdown.seconds === 0
    ) {
      return renderStream();
    } else {
      return renderCountDown();
    }
  };

  return <Container className={["p-5", style.whiteBackground].join(' ')}>{render()}</Container>
}
export default EventsMobile;
