import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { history } from "../..";
import { Card, Container } from "react-bootstrap";
import agent from "../../app/api/agent";
import { EmailConfirmation } from "../../app/models/EmailConfirmation";

function VerifyEmail() {
  const Status = {
    Verifying: "Verifying",
    Failed: "Failed",
    Success: "Success",
  };
  const useQuery = new URLSearchParams(useLocation().search);

  const emailConfirm: EmailConfirmation = {
    email: useQuery.get("email") as string,
    token: useQuery.get("token") as string,
  };

  const [status, setStatus] = useState(Status.Verifying);
  const [error, setError] = useState("");

  function handleConfirmEmailResend() {
    if (
      emailConfirm.email.includes(" ") ||
      emailConfirm.email.includes("%20")
    ) {
      emailConfirm.email = emailConfirm.email
        .replaceAll(" ", "+")
        .replaceAll("%20", "+");
    }
    agent.Account.resendEmailConfirm(emailConfirm.email)
      .then(() => {
        toast.success("Resend Success!");
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    if (emailConfirm.token && emailConfirm.email) {
      if (
        emailConfirm.email.includes(" ") ||
        emailConfirm.email.includes("%20")
      ) {
        emailConfirm.email = emailConfirm.email
          .replaceAll(" ", "+")
          .replaceAll("%20", "+");
      }
      agent.Account.verifyEmail(emailConfirm.token, emailConfirm.email)
        .then(() => {
          setStatus(Status.Success);
        })
        .catch((err) => {
          setError(err.response.data);
          setStatus(Status.Failed);
        });
    }
  }, []);

  const handleLogin = async () => {
    history.push("/login");
  };

  const STATUS_MESSAGE = [
    {
      status: status === Status.Failed,
      iClass: "fa-regular text-danger fa-circle-xmark pe-3",
      h4Class: "failed registration-text small-center",
    },
    {
      status: status === Status.Success,
      iClass: "fa-regular text-success fa-circle-check pe-3",
      h4Class: "successful-registration-text",
    },
    {
      status: status === Status.Verifying,
      iClass: "fa-regular text-warning fa-circle-xmark pe-3",
      h4Class: "registration-text waiting",
    },
  ];

  function getBody() {
    switch (status) {
      case Status.Verifying:
        return <p>Verifying...</p>;
      case Status.Failed:
        return (
          <div className="small-center">
            {error === "email_has_been_verified" ? (
              <div>
                <p className=" ">Email has been verified</p>
                <button onClick={handleLogin} className="btn btn-primary mt-4">
                  Login
                </button>
              </div>
            ) : (
              <div>
                <p className=" ">Verification Failed</p>
                <div>
                  <button
                    className="btn btn-danger mt-4"
                    onClick={handleConfirmEmailResend}
                  >
                    Resend Verification
                  </button>
                </div>
              </div>
            )}
          </div>
        );
      case Status.Success:
        return (
          <div>
            <p>Verification Success!</p>
            <button onClick={handleLogin} className="btn btn-primary">
              Login
            </button>
          </div>
        );
    }
  }

  return (
    <>
      <Container
        fluid
        className="d-flex justify-content-center align-items-center pb-5 login"
      >
        <Card
          style={{ width: "800px" }}
          className="newFont prevent-select card-shadow login-card-size login-card"
        >
          <Card.Header>
            <p className="login-card-title">Register Status</p>
          </Card.Header>
          <Card.Body className="card-padding card-font-size">
            <Card.Title className=" d-flex align-items-center">
              {STATUS_MESSAGE.map((c) => (
                <>
                  {c.status && (
                    <>
                      <i style={{ fontSize: "60px" }} className={c.iClass} />
                      <h4 className={c.h4Class}>Email Verification</h4>
                    </>
                  )}
                </>
              ))}
            </Card.Title>
            <div>{getBody()}</div>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default observer(VerifyEmail);
