import { Chip, InputLabel, ListItem, Paper } from "@mui/material";
import {
  InvitationEmails,
  InvitationLinks,
} from "../../app/models/InvitationLinks";
import InvitationCards from "../components/InvitationCards";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { useState } from "react";
import { toast } from "react-toastify";
import agent from "../../app/api/agent";
import { useTranslation } from "react-i18next";
import InvitationList from "../components/invitations/InvitationList";
import { observer } from "mobx-react-lite";

function Invitations() {
  const INITIAL_VALUES: InvitationEmails = {
    emails: [],
    roleId: "",
  };
  const [invitedUsers, setInvitedUsers] =
    useState<InvitationEmails>(INITIAL_VALUES);
  const { t } = useTranslation();
  const [emailInput, setEmailInput] = useState<string>("");
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);

  const handleUserInviteDelete = (index: number) => (): void => {
    const newInvitedUsers = invitedUsers;
    newInvitedUsers.emails.filter((email, i) => {
      return i !== index;
    });
    setInvitedUsers(newInvitedUsers);
  };

  const handleAddUserEmail = (): void => {
    if (!/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i.test(emailInput)) {
      toast.error(t("EMAIL_ERROR_1"));
    } else {
      let inputValue = emailInput.trim()
      let emails = inputValue.split(';')
      emails = emails.filter(email => email !== "")
      for(const emailItem of emails){
        if (invitedUsers.emails.includes(emailItem)) {
          toast.error(t("EMAIL_ERROR_2"));
        } else {
          invitedUsers.emails.push(emailItem.trim());
        }
      }
      
      setEmailInput("");
    }
  };

  const handleInputChange = (e: any): void => {
    const { name, value } = e.target;
    setInvitedUsers({ ...invitedUsers, [name]: value });
  };

  const handleSubmit = async (): Promise<void> => {
    if (!invitedUsers.emails.length) {
      toast.error(t("EMAIL_ERROR_3"));
    } else if (!invitedUsers.roleId) {
      toast.error(t("SELECT_ROLE"));
    } else {
      setLoadingBtn(true);
      await agent.Invitation.send(invitedUsers)
        .then(() => {
          setInvitedUsers(INITIAL_VALUES);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoadingBtn(false));
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent): void => {
    if (e.key === "Enter") {
      handleAddUserEmail();
    }
  };

  const invitationRoles = [
    {
      name: t("AUDIENCE"),
      role: InvitationLinks.Audience,
    },
    {
      name: t("DELEGATION"),
      role: InvitationLinks.Delegation,
    },
    {
      name: t("MEDIA"),
      role: InvitationLinks.Media,
    },
    {
      name: t("SPEAKER"),
      role: InvitationLinks.Speaker,
    },
    {
      name: t("AMBASSADOR"),
      role: InvitationLinks.Ambassador,
    },
  ];

  return (
    <div className="main">
      <div className="d-flex justify-content-around align-items-center flex-wrap gap-3">
        {invitationRoles.map((role, index) => (
          <InvitationCards key={index} name={role.name} role={role.role} />
        ))}
      </div>

      <div className="invitation-card">
        <div className="invitation-title">
          <p>{t("MULTIPLE_INVITE")}</p>
        </div>
        {invitedUsers.emails.length > 0 && (
          <Paper
            sx={{
              display: "flex",
              overflowX: "scroll",
              listStyle: "none",
              background: "transparent",
              boxShadow: "none",
              p: 0.5,
              m: 0,
            }}
            component="ul"
          >
            {invitedUsers.emails.map((data, index) => {
              return (
                <ListItem sx={{ color: "white" }} key={data}>
                  <Chip
                    label={data}
                    sx={{ color: "black" }}
                    onDelete={handleUserInviteDelete(index)}
                  />
                </ListItem>
              );
            })}
          </Paper>
        )}
        <div className="container mt-2 mb-4">
          <div className="row">
            <div className="col-md-8 mt-2">
              <InputLabel>{t("EMAIL_INVITE")}</InputLabel>
              <InputGroup>
                <Form.Control
                  value={emailInput}
                  onChange={(e) => setEmailInput(e.target.value)}
                  placeholder="exmple@gmail.com"
                  type="email"
                  onKeyUp={handleKeyPress}
                />
                <Button
                  variant="secondary"
                  type="button"
                  onClick={handleAddUserEmail}
                >
                  {t("ADD")}
                </Button>
              </InputGroup>
            </div>
            <div className="col-md-4 mt-2">
              <InputLabel>{t("INVITATION_ROLE_SELECT")}</InputLabel>
              <Form.Select value={invitedUsers.roleId} required name="roleId" onChange={handleInputChange}>
                <option>{t("ROLES")}</option>
                {invitationRoles.map((role, index) => (
                  <option key={index} value={role.role}>
                    {role.name}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Button disabled={loadingBtn} onClick={handleSubmit}>
            {loadingBtn ? (
              <Spinner animation="border" size="sm" variant="light" />
            ) : (
              t("SEND_INVITE")
            )}
          </Button>
        </div>
      </div>

      <InvitationList />
    </div>
  );
}
export default observer(Invitations);
