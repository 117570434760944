import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

interface Props {
  onChange: any;
  label: string;
  value: boolean;
  title?: string;
}

function CustomCheckBox({ onChange, label, value, title = '' }: Props) {
  return (
    <FormGroup className="ms-1">
      <FormControlLabel
        control={<Checkbox className="color-auth" onChange={onChange} />}
        label={label}
        checked={value}
        title={title}
      />
    </FormGroup>
  );
}
export default CustomCheckBox;
