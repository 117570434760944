import { makeAutoObservable, runInAction } from "mobx";
import { EmailActive, PassportSent, Roles, User } from "../models/User";
import agent from "../api/agent";
import { Ticket } from "../models/Ticket";
import { store } from "./store";

export default class ManageUsersStore {
  usersRegistry = new Map<string, User>();

  constructor() {
    makeAutoObservable(this);
  }

  get getAllUsers() {
    const userList = Array.from(this.usersRegistry.values());
    if (store.delegationStore.delegation) {
      return userList.filter(
        (user) => user.delegationId === store.delegationStore.delegation?.id
      );
    } else return userList;
  }
  loadAmbassadors = async () => {
    this.usersRegistry = new Map<string, User>();
    const users = await agent.Account.listAmbassadors();
    runInAction(() => {
      users
        .filter((user) => !user.userRoles.includes(Roles.Administrator))
        .forEach((user) => {
          this.usersRegistry.set(user.id, user);
        });
    });
  };
  loadMedia = async () => {
    this.usersRegistry = new Map<string, User>();
    const users = await agent.Account.listMedia();
    runInAction(() => {
      users
        .filter((user) => !user.userRoles.includes(Roles.Administrator))
        .forEach((user) => {
          this.usersRegistry.set(user.id, user);
        });
    });
  };
  loadAudience = async () => {
    this.usersRegistry = new Map<string, User>();
    const users = await agent.Account.listAudience();
    runInAction(() => {
      users
        .filter((user) => !user.userRoles.includes(Roles.Administrator))
        .forEach((user) => {
          this.usersRegistry.set(user.id, user);
        });
    });
  };
  loadAllUsers = async () => {
    this.usersRegistry = new Map<string, User>();
    const users = await agent.Account.list();
    runInAction(() => {
      users
        .filter((user) => !user.userRoles.includes(Roles.Administrator))
        .forEach((user) => {
          this.usersRegistry.set(user.id, user);
        });
    });
  };

  deleteUser = async (id: string) => {
    await agent.Account.delete(id);
    runInAction(() => {
      let user = this.usersRegistry.get(id);
      if (user) {
        user.isDeleted = true;
        this.usersRegistry.set(id, user);
      }
    });
  };

  attendance = async (ticket: Ticket) => {
    await agent.TicketApi.attendance(ticket);
    runInAction(() => {
      let user = this.usersRegistry.get(ticket.userId);
      if (user) {
        user.hasAttended = ticket.attendance;
        this.usersRegistry.get(user.id);
      }
    });
  };

  activateEmail = async (active: EmailActive) => {
    await agent.Account.activateEmail(active);
    runInAction(() => {
      let user = this.usersRegistry.get(active.userId);
      if (user) {
        user.emailConfirmed = active.emailConfirmed;
        this.usersRegistry.set(active.userId, user);
      }
    });
  };
  updatePassportStatus = async (data: PassportSent) => {
    await agent.Account.updatePassportStatus(data);
    runInAction(() => {
      let user = this.usersRegistry.get(data.userId);
      if (user) {
        user.hasSentPassport = data.PassportStatus;
        this.usersRegistry.set(data.userId, user);
      }
    });
  };
}
