import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StoreContext, store } from "./app/stores/store";
import CustomRouter from "./app/common/CustomRouter";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-toastify/dist/ReactToastify.css";
import "./design/style/globalStyle.scss";
import "swiper/css";
import utcPlugin from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { createBrowserHistory } from "history";
import "./utilities/i18n";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import "primeflex/primeflex.css"; // css utility
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "react-phone-input-2/lib/material.css";
dayjs.extend(utcPlugin);

export let history = createBrowserHistory();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <StoreContext.Provider value={store}>
    <CustomRouter history={history}>
      <React.Suspense fallback="loading...">
        <PrimeReactProvider>
          <App />
        </PrimeReactProvider>
      </React.Suspense>
    </CustomRouter>
  </StoreContext.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
