import { OutlinedInput } from "@mui/material";
import { Dayjs } from "dayjs";
import { ChangeEventHandler } from "react";

interface Props {
  value?: string | number | boolean | Dayjs | Date;
  onChange:
    | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  name: string;
  type?: string;
  required?: boolean;
  label?: string;
  accept?: string;
  title?: string
}

function CustomOutlinedInput({
  value,
  onChange,
  name,
  type = "text",
  required = true,
  label,
  accept,
  title = '',
}: Props) {
  return (
    <OutlinedInput
      value={value}
      onChange={onChange}
      name={name}
      type={type}
      fullWidth
      required={required}
      inputProps={{ accept: accept }}
      label={label}
      title={title}
    />
  );
}
export default CustomOutlinedInput;
