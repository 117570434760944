import { useState } from "react";
import { Button, FormControl, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props {
  show: boolean;
  onClick: (key: string) => void;
}

function KeySettingModal({ show, onClick }: Props) {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>("");

  return (
    <Modal centered show={show}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Put your code")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormControl type="text" onChange={(e) => setValue(e.target.value)} />
        <Button className="mt-2" onClick={() => onClick(value)}>OK</Button>'
      </Modal.Body>
    </Modal>
  );
}
export default KeySettingModal;
