import dayjs from "dayjs";

export interface Agenda {
  id: string;
  nrRendor: number;
  fromTime: dayjs.Dayjs | Date;
  endingTime: dayjs.Dayjs | Date;
  speaker: string;
  description: string;
  hallNumber: number;
}

export const Halls = [
  {
    value: 1,
    label: "Beethoven Hall",
  },
  {
    value: 2,
    label: "Strauss 1",
  },
  {
    value: 3,
    label: "Strauss 2",
  },
  {
    value: 4,
    label: "Verdi Hall",
  },
  {
    value: 5,
    label: "Main Lobby",
  },
  {
    value: 6,
    label: "Lobby Restaurant", 
  },
];
