import { observer } from "mobx-react-lite";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useStore } from "../../../app/stores/store";
import { emptyRoomCreate } from "../../../app/stores/roomStore";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { RoomType } from "../../../app/models/Hotel";
import CustomOutlinedInput from "../../components/CustomOutlinedInput";
import { ToggleButton } from "primereact/togglebutton";
import CryptoJS from "crypto-js";
import { Autocomplete, TextField } from "@mui/material";

interface Props {
  dialog: boolean;
  hideDialog: () => void;
  decryptor: string;
}

function RoomSave({ dialog, hideDialog, decryptor }: Props) {
  const { roomStore } = useStore();
  const {
    roomCreate,
    setRoomCreate,
    createRoom,
    roomOccupation,
    setRoomOccupation,
    editOccupation,
    delegationMemberId,
    setDelegationMemberId,
    initialRoomOccupation,
  } = roomStore;
  const [error, setError] = useState<string>("");
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [createMode, setCreateMode] = useState<boolean>(false);
  const { delegationStore } = useStore();
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const [startingNr, setStartingNr] = useState<number>(0);
  const [endingNr, setEndingNr] = useState<number>(0);

  useEffect(() => {
    delegationStore.getDelegations().finally(() => setLoadingInitial(false));
  }, [delegationStore]);

  const createArray = (start: number, end: number): number[] => {
    // Initialize an empty array to store the result
    let result = [];

    // Iterate from the start number to the end number
    for (let i = start; i <= end; i++) {
      // Push each number to the result array
      result.push(i);
    }

    // Return the resulting array
    return result;
  };

  const encryptInformation = (text: number): string => {
    return CryptoJS.AES.encrypt(text + "", decryptor).toString();
  };

  const saveRoom = async () => {
    if (!startingNr && !createMode) {
      setError("Starting Number is Required");
    } else if (!endingNr && !createMode) {
      setError("Ending Number is Required");
    } else if (!endingNr && !createMode) {
      setError("Ending Number is Required");
    } else if (!endingNr && createMode) {
      setError("Room Number is Required");
    } else {
      setError("");
      setLoadingBtn(true);
      const roomsArray = createArray(startingNr, endingNr).map((roomNumber) => {
        return encryptInformation(roomNumber);
      });
      roomCreate.rooms = roomsArray;

      await createRoom(roomCreate)
        .then(() => {
          toast.success("Room Created");
          setRoomCreate(emptyRoomCreate);
          hideDialog();
        })
        .catch((err) => console.log(err))
        .finally(() => setLoadingBtn(false));
    }
  };

  const editRoom = async () => {
    setLoadingBtn(true);
    roomOccupation!.delegationId = delegationMemberId;
    await editOccupation(roomOccupation!)
      .then(() => {
        toast.success("Room Edited");
        hideDialog();
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingBtn(false));
  };

  const onCategoryChange = (e: RadioButtonChangeEvent) => {
    const { name, value } = e.target;
    setRoomCreate({ ...roomCreate, [name]: value });
  };

  const getDelgationsAndSpeakers = () => {
    return delegationStore.delegations
      .filter((delegation) => delegation.delegationName)
      .map((delegation) => ({
        label: delegation.delegationName,
        id: delegation.id,
      }));
  };

  const delegationMembers = getDelgationsAndSpeakers();

  let delegationId;

  if (delegationMemberId) {
    delegationId = delegationMembers.find(
      (member) => member.id === delegationMemberId
    );
  } else {
    delegationId = delegationMembers.find(
      (member) => member.id === roomOccupation?.delegationId
    );
  }

  const dialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        disabled={
          loadingBtn ||
          (roomOccupation &&
            roomOccupation.delegationId === delegationMemberId &&
            roomOccupation.occupied === initialRoomOccupation?.occupied)
        }
        loading={loadingBtn}
        label="Save"
        icon="pi pi-check"
        onClick={roomOccupation ? editRoom : saveRoom}
      />
    </React.Fragment>
  );

  console.log(delegationId);

  return (
    <Dialog
      visible={dialog}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={roomOccupation ? "Edit Occupation" : "Create Room"}
      modal
      className="p-fluid"
      footer={dialogFooter}
      onHide={hideDialog}
    >
      {roomOccupation ? (
        <div className="formgrid grid justify-content-center">
          <div className="field col-6 pt-2">
            <Autocomplete
              disablePortal
              options={delegationMembers}
              disabled={loadingInitial}
              onChange={(e, newValue: any) => {
                if (newValue) {
                  setDelegationMemberId(newValue.id);
                } else {
                  setDelegationMemberId("00000000-0000-0000-0000-000000000000");
                }
              }}
              value={delegationId}
              isOptionEqualToValue={(option, value) => {
                return value === "" || (value && option.id === value.id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Hotel Memeber"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            <ToggleButton
              className="mt-3"
              onLabel="Occupied"
              offLabel="Not Occupied"
              onIcon="pi pi-check"
              offIcon="pi pi-times"
              checked={roomOccupation.occupied}
              onChange={(e) =>
                setRoomOccupation({ ...roomOccupation, occupied: e.value })
              }
            />
          </div>
        </div>
      ) : (
        <>
          <div className="formgrid grid">
            <div className="field col-6 ">
              <label htmlFor="name" className="font-bold">
                Create Type
              </label>
              <ToggleButton
                onLabel="Multiple"
                offLabel="Single"
                checked={createMode}
                onChange={(e) => setCreateMode(e.value)}
              />
            </div>
          </div>
          {createMode ? (
            <div className="field">
              <label htmlFor="name" className="font-bold">
                Room Number
              </label>
              <CustomOutlinedInput
                onChange={(e) => {
                  setStartingNr(parseInt(e.target.value));
                  setEndingNr(parseInt(e.target.value));
                }}
                value={startingNr}
                name="roomNr"
                type="number"
              />
            </div>
          ) : (
            <div className="formgrid grid">
              <div className="field col">
                <label htmlFor="name" className="font-bold">
                  Starting Number
                </label>
                <CustomOutlinedInput
                  onChange={(e) => setStartingNr(parseInt(e.target.value))}
                  value={startingNr}
                  name="startingNr"
                  type="number"
                />
              </div>
              <div className="field col">
                <label htmlFor="name" className="font-bold">
                  Ending Number
                </label>
                <CustomOutlinedInput
                  onChange={(e) => setEndingNr(parseInt(e.target.value))}
                  value={endingNr}
                  name="endingNr"
                  type="number"
                />
              </div>
            </div>
          )}
          <div className="field">
            <label className="mb-3 font-bold">Room Type</label>
            <div className="formgrid grid">
              <div className="field-radiobutton col-6">
                <RadioButton
                  inputId="category1"
                  name="type"
                  value={RoomType.NonVip}
                  onChange={onCategoryChange}
                  checked={roomCreate.type === RoomType.NonVip}
                />
                <label htmlFor="category1">Non Vip</label>
              </div>
              <div className="field-radiobutton col-6">
                <RadioButton
                  inputId="category2"
                  name="type"
                  value={RoomType.Vip}
                  onChange={onCategoryChange}
                  checked={roomCreate.type === RoomType.Vip}
                />
                <label htmlFor="category2">Vip</label>
              </div>
            </div>
          </div>
          {error && <small className="p-error">{error}</small>}
        </>
      )}
    </Dialog>
  );
}
export default observer(RoomSave);
