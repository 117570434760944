import { observer } from "mobx-react-lite";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MdSupervisorAccount } from "react-icons/md";
import { InvitationLinks } from "../../../app/models/InvitationLinks";
import { useStore } from "../../../app/stores/store";
import { Col, Row } from "react-bootstrap";
import "./RegistrationCard.module.scss";
import "./registerCard.scss";
import CustomTextField from "../CustomTextField";
import CustomCheckBox from "../CustomCheckBox";
import PhoneInput from "react-phone-input-2";
import { useEffect, useState } from "react";
// import { toast } from "react-toastify";
// import { Chip, ListItem, Paper } from "@mui/material";
// import { v4 as uuid } from "uuid";
// import { DelegationDriver } from "../../../app/models/RegisterDelegation";
import CustomSelect from "../CustomSelect";
import { MeansOfTransport } from "../../../utilities/constGroup";

function RegisterDelegationCard() {
  const { registrationsStore } = useStore();
  const { role, wizard, error, delegationDetails, setDelegationDetails } =
    registrationsStore;
  // const INITIAL_DRIVER = {
  //   id: uuid(),
  //   driverFirstName: "",
  //   driverLastName: "",
  //   driverPhoneNumber: "",
  //   driverPlate: "",
  // };
  // const [driver, setDriver] = useState(INITIAL_DRIVER); // todo: remove
  const [nrOfCars, setNrOfCars] = useState(1);
  const { t } = useTranslation();

  const roleValueToEnum = Object.fromEntries(
    Object.entries(InvitationLinks).map(([key, value]) => [value, key])
  );

  function handleInputChange(e: any) {
    const { name, value } = e.target;
    setDelegationDetails({ ...delegationDetails, [name]: value });
  }

  useEffect(() => {
    delegationDetails.meanOfTransport = MeansOfTransport.Airplane;
  }, []);

  // function handleInputChangeDriver(e: any) {
  //   const { name, value } = e.target;
  //   setDriver({ ...driver, [name]: value });
  // }

  function handlePhoneNumberChange(value: string, name: string) {
    setDelegationDetails({ ...delegationDetails, [name]: value });
  }

  // function handlePhoneNumberChangeDriver(value: string, name: string) {
  //   setDriver({ ...driver, [name]: value });
  // }

  function handleInputChangeNumberOfCars(e: any) {
    const { value } = e;
    setNrOfCars(parseInt(value));
    handleInputChangeNumber(e);
  }

  function handleInputChangeNumber(e: any) {
    const { name, value } = e.target;
    let inputValue = parseInt(value, 10);

    if (!isNaN(inputValue) && inputValue >= 1) {
      inputValue = Math.min(inputValue, 30);

      const userData = {
        ...delegationDetails,
        [name]: inputValue,
      };

      setDelegationDetails(userData);
    }
  }

  // const handleDriverSubmit = (e: any) => {
  //   e.preventDefault();
  //   if (!driver.driverPhoneNumber) {
  //     toast.error("Please add Phone Number!");
  //   } else {
  //     registrationsStore.addDriverToDelegation(driver);
  //     setDriver(INITIAL_DRIVER);
  //   }
  // };

  // const handleUserInviteDelete = (chipToDelete: DelegationDriver) => () => {
  //   const delegation = { ...delegationDetails };
  //   delegation.driverList = delegation.driverList?.filter(
  //     (driver) => driver.id !== chipToDelete.id
  //   );
  //   setDelegationDetails(delegation);
  // };

  return (
    <Container fluid className="register-card">
      <div className="register-card-header">
        <MdSupervisorAccount className="icon-color" size={120} />
        <p>{roleValueToEnum[role]}</p>
      </div>
      <div className="register-card-body">
        {wizard === 2 && (
          <>
            <h6 className="ps-1">Travel Details</h6>
            <Row>
              <Col sm={6}>
                <CustomTextField
                  value={delegationDetails.arrivalTime}
                  onChange={handleInputChange}
                  name="arrivalTime"
                  type="date"
                  label="Arrival"
                  error={error}
                />
              </Col>
              <Col sm={6}>
                <CustomTextField
                  value={delegationDetails.departureTime}
                  onChange={handleInputChange}
                  name="departureTime"
                  type="date"
                  label="Departure"
                  error={error}
                />
              </Col>
            </Row>

            <CustomSelect
              value={delegationDetails.meanOfTransport}
              name="meanOfTransport"
              onChange={handleInputChange}
              options={[MeansOfTransport.Airplane, MeansOfTransport.Car]}
              label={"Mean of Transport"}
              error={error}
            />
            {delegationDetails.meanOfTransport === MeansOfTransport.Car && (
              <>
                <CustomTextField
                  value={delegationDetails.travelNrOfCars}
                  onChange={handleInputChangeNumberOfCars}
                  name="travelNrOfCars"
                  type="number"
                  label="Number of Cars"
                  error={error}
                />
                <Row>
                  <Col sm={6}>
                    <CustomTextField
                      value={delegationDetails.travelCarModel}
                      onChange={handleInputChange}
                      name="travelCarModel"
                      label="Car Model"
                      error={error}
                    />
                  </Col>
                  <Col sm={6}>
                    <CustomTextField
                      value={delegationDetails.travelCarPlates}
                      onChange={handleInputChange}
                      name="travelCarPlates"
                      label="Car Plates"
                      error={error}
                    />
                  </Col>
                </Row>
              </>
            )}
            {delegationDetails.meanOfTransport ===
              MeansOfTransport.Airplane && (
              <>
                <Row>
                  <Col sm={6}>
                    <CustomTextField
                      value={delegationDetails.arrivingFromCountry}
                      onChange={handleInputChange}
                      name="arrivingFromCountry"
                      type="text"
                      label="Country Arriving From"
                      error={error}
                    />
                  </Col>
                  <Col sm={6}>
                    <CustomTextField
                      value={delegationDetails.arrivingFromCity}
                      onChange={handleInputChange}
                      name="arrivingFromCity"
                      type="text"
                      label="City Arriving From"
                      error={error}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <CustomTextField
                      value={delegationDetails.countryDepartingTo}
                      onChange={handleInputChange}
                      name="countryDepartingTo"
                      type="text"
                      label="Country Departing To"
                      error={error}
                    />
                  </Col>
                  <Col sm={6}>
                    <CustomTextField
                      value={delegationDetails.cityDepartingTo}
                      onChange={handleInputChange}
                      name="cityDepartingTo"
                      type="text"
                      label="City Departing To"
                      error={error}
                    />
                  </Col>
                </Row>
              </>
            )}
            {/* do you need a driver part */}
            {/* <CustomCheckBox
              value={delegationDetails.isDriverNeeded}
              onChange={() =>
                setDelegationDetails({
                  ...delegationDetails,
                  isDriverNeeded: !delegationDetails.isDriverNeeded,
                })
              }
              label={"Do you need a Driver ?"}
            />
            {!delegationDetails.isDriverNeeded &&
              delegationDetails.meanOfTransport !== "Car" && (
                <Form
                  onSubmit={handleDriverSubmit}
                  className="register-card-body p-0"
                >
                  {delegationDetails.driverList.length > 0 && (
                    <Paper
                      sx={{
                        display: "flex",
                        overflowX: "scroll",
                        listStyle: "none",
                        background: "transparent",
                        boxShadow: "none",
                        p: 0.5,
                        m: 0,
                      }}
                      component="ul"
                    >
                      {delegationDetails.driverList?.map((data) => {
                        return (
                          <ListItem key={data.id}>
                            <Chip
                              label={`${data.driverFirstName} ${data.driverLastName}`}
                              onDelete={handleUserInviteDelete(data)}
                            />
                          </ListItem>
                        );
                      })}
                    </Paper>
                  )}
                  <Row>
                    <Col sm={6}>
                      <CustomTextField
                        value={driver.driverFirstName}
                        onChange={handleInputChangeDriver}
                        name="driverFirstName"
                        label={"First Name"}
                      />
                    </Col>
                    <Col sm={6}>
                      <CustomTextField
                        value={driver.driverLastName}
                        onChange={handleInputChangeDriver}
                        name="driverLastName"
                        label={"Last Name"}
                      />
                    </Col>
                  </Row>
                  <PhoneInput
                    disableCountryGuess
                    onlyCountries={["xk"]}
                    country="xk"
                    specialLabel={t("PHONE_NUMBER")}
                    inputClass="phone-input-style"
                    inputStyle={{ width: "100%", height: "54px" }}
                    value={driver.driverPhoneNumber}
                    onChange={(value) =>
                      handlePhoneNumberChangeDriver(value, "driverPhoneNumber")
                    }
                  />
                  <CustomTextField
                    value={driver.driverPlate}
                    onChange={handleInputChangeDriver}
                    name="driverPlate"
                    label="Car License Plate"
                  />
                  <Button type="submit">Add</Button>
                </Form>
              )}
            {delegationDetails.isDriverNeeded && (
              <CustomTextField
                value={delegationDetails.driverNrOfCars}
                onChange={handleInputChangeNumber}
                name="driverNrOfCars"
                label="Number of Cars Needed"
                error={error}
                type="number"
              />
            )} */}
          </>
        )}
        {wizard === 3 && (
          <>
            <h6 className="ps-1">Accomodation details</h6>
            <Row>
              <Col sm={12}>
                <h6 className="ps-1">Do you need a accommodation ?</h6>
              </Col>
              <Col sm={4}>
                <CustomCheckBox
                  value={delegationDetails.isHotelNeeded === true}
                  onChange={() =>
                    setDelegationDetails({
                      ...delegationDetails,
                      isHotelNeeded: true,
                    })
                  }
                  label={"Yes"}
                />
              </Col>
              <Col sm={4}>
                <CustomCheckBox
                  value={delegationDetails.isHotelNeeded === false}
                  onChange={() =>
                    setDelegationDetails({
                      ...delegationDetails,
                      isHotelNeeded: false,
                    })
                  }
                  label={"No"}
                />
              </Col>
            </Row>

            {delegationDetails.isHotelNeeded === false && (
              <Row>
                <Col sm={12}>
                  <h6 className="ps-1">
                    Have you already made accommodation arrangements?
                  </h6>
                </Col>
                <Col sm={4}>
                  <CustomCheckBox
                    value={
                      delegationDetails.hasAccommodationArrangements === true
                    }
                    onChange={() =>
                      setDelegationDetails({
                        ...delegationDetails,
                        hasAccommodationArrangements: true,
                      })
                    }
                    label={"Yes"}
                  />
                </Col>
                <Col sm={4}>
                  <CustomCheckBox
                    value={
                      delegationDetails.hasAccommodationArrangements === false
                    }
                    onChange={() =>
                      setDelegationDetails({
                        ...delegationDetails,
                        hasAccommodationArrangements: false,
                      })
                    }
                    label={"No"}
                  />
                </Col>
              </Row>
            )}
            {delegationDetails.isHotelNeeded === false &&
              delegationDetails.hasAccommodationArrangements && (
                <CustomTextField
                  value={delegationDetails.hotelName}
                  onChange={handleInputChange}
                  name="hotelName"
                  error={error}
                  label={"Hotel Name"}
                />
              )}
            {delegationDetails.isHotelNeeded && (
              <p>
                For futher details regarding accommodation, please consult the
                Logistical Note shared by the organizers.
              </p>
            )}
          </>
        )}
        {wizard === 4 && (
          <>
            <h6 className="ps-1">Person of Contact Information</h6>
            <Row>
              <Col sm={6}>
                <CustomTextField
                  value={delegationDetails.personOfContactFirstName}
                  onChange={handleInputChange}
                  name="personOfContactFirstName"
                  label="First Name"
                  error={error}
                />
              </Col>
              <Col sm={6}>
                <CustomTextField
                  value={delegationDetails.personOfContactLastName}
                  onChange={handleInputChange}
                  name="personOfContactLastName"
                  label="Last Name"
                  error={error}
                />
              </Col>
            </Row>
            <CustomTextField
              value={delegationDetails.personOfContactEmail}
              onChange={handleInputChange}
              name="personOfContactEmail"
              label="Email"
              error={error}
            />
            <PhoneInput
              disableCountryGuess
              country="xk"
              specialLabel={t("PHONE_NUMBER")}
              inputClass="phone-input-style"
              inputStyle={{ width: "100%", height: "54px" }}
              value={delegationDetails.personOfContactPhoneNumber}
              onChange={(value) =>
                handlePhoneNumberChange(value, "personOfContactPhoneNumber")
              }
            />
          </>
        )}
      </div>
    </Container>
  );
}
export default observer(RegisterDelegationCard);
