import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Button, Modal, Spinner } from "react-bootstrap";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import CachedIcon from "@mui/icons-material/Cached";
import PencilIcon from "@mui/icons-material/Edit";
import ComponentLoader from "../loaders/ComponentLoader";
import CreateInvitation from "./CreateInvitation";
import { InvitationList as InvitationModel } from "../../../app/models/InvitationList";
import agent from "../../../app/api/agent";
import {
  InvitationEmails,
  InvitationStatus,
} from "../../../app/models/InvitationLinks";
import {
  inviteLinkToString,
  mapRoleToString,
} from "../../../utilities/constGroup";

function InvitationList() {
  const { userStore, invitationsStore } = useStore();
  const { invitationList, deleteInvitation, editInvitationStatus } =
    invitationsStore;
  const InitialModalValues = {
    delete: false,
    create: false,
    resend: false,
    status: false,
  };
  const [show, setShow] = useState(InitialModalValues);
  const { t } = useTranslation();
  const [loadingIntital, setLoadingInitial] = useState(true);
  const [selectedInvitation, setSelectedInvitation] =
    useState<InvitationModel | null>(null);
  const [loadingBtn, setLoadingBtn] = useState(false);

  useEffect(() => {
    invitationsStore
      .getInvitationList()
      .finally(() => setLoadingInitial(false));
  }, [invitationsStore]);

  if (loadingIntital) return <ComponentLoader />;

  const handleShow = (
    action: "delete" | "create" | "resend" | "status",
    state: boolean
  ): void => {
    setShow({ ...show, [action]: state });
  };

  const handleAgree = async () => {
    setLoadingBtn(true);
    await deleteInvitation(selectedInvitation!.id)
      .then(() => {
        handleShow("delete", false);
      })
      .finally(() => setLoadingBtn(false));
  };

  const handleResend = async () => {
    setLoadingBtn(true);
    if (selectedInvitation?.email && selectedInvitation.role) {
      const obj: InvitationEmails = {
        roleId: inviteLinkToString(selectedInvitation.role),
        emails: [selectedInvitation.email],
      };
      try {
        await agent.Invitation.send(obj);
        handleShow("resend", false);
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingBtn(false);
      }
    } else {
      console.error("Invalid selected invitation data");
      setLoadingBtn(false);
    }
  };

  const handleEditStatus = async () => {
    setLoadingBtn(true);
    if (selectedInvitation) {
      const obj: InvitationStatus = {
        id: selectedInvitation.id,
        hasRegistered: !selectedInvitation.hasRegistered!,
      };
      await editInvitationStatus(obj)
        .then(() => handleShow("status", false))
        .catch((err) => console.log(err))
        .finally(() => setLoadingBtn(false));
    }
  };

  const INVITATION_DATA = invitationList.map((x) => ({
    firstName: x.firstName,
    lastName: x.lastName,
    email: x.email,
    role: mapRoleToString(x.role!),
    hasRegistered: x.hasRegistered ? "Yes" : "No",
    action: (
      <div className="d-flex">
        <Tooltip
          onClick={() => {
            handleShow("status", true);
            setSelectedInvitation(x);
          }}
          title="Edit Status"
        >
          <IconButton>
            <PencilIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          onClick={() => {
            handleShow("resend", true);
            setSelectedInvitation(x);
          }}
          title="Resend Invitation"
        >
          <IconButton>
            <CachedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          onClick={() => {
            setSelectedInvitation(x);
            handleShow("delete", true);
          }}
          title="Delete"
        >
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    ),
  }));

  const INVITATION_COLUMNS = [
    { name: "firstName", label: t("FIRST_NAME") },
    { name: "lastName", label: t("LAST_NAME") },
    { name: "email", label: t("EMAIL") },
    { name: "role", label: t("ROLE") },
    { name: "hasRegistered", label: "Has Registered" },
    {
      name: "action",
      label: t("ACTION"),
      options: {
        filter: false,
        sort: false,
        display: userStore.isAdministrator,
        setCellProps: () => ({
          style: { width: "100px" },
        }),
      },
    },
  ];

  return (
    <div className="mt-5">
      <MUIDataTable
        title={"Invitation List"}
        data={INVITATION_DATA}
        columns={INVITATION_COLUMNS}
        options={{
          responsive: "vertical",
          viewColumns: true,
          selectableRows: "none",
          rowsPerPage: 500,
          rowsPerPageOptions: [500, 1000, 1500],
          tableBodyHeight: "auto",
          customToolbar: () => (
            <Tooltip
              onClick={() => handleShow("create", true)}
              title={t("ADD")}
            >
              <IconButton>
                <AddIcon />
              </IconButton>
            </Tooltip>
          ),
        }}
      />
      {/* Create */}
      <Modal
        onHide={() => handleShow("create", false)}
        show={show.create}
        size="lg"
        centered
      >
        <CreateInvitation
          handleCloseModal={() => handleShow("create", false)}
        />
      </Modal>

      {/* Delete */}
      <Dialog open={show.delete} onClose={() => handleShow("delete", false)}>
        <DialogContent>
          {t("CONFIRM_DELETE_2")} "{selectedInvitation?.email}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleShow("delete", false)}>
            {t("CANCEL")}
          </Button>
          <Button disabled={loadingBtn} variant="danger" onClick={handleAgree}>
            {loadingBtn ? (
              <Spinner animation="border" size="sm" variant="light" />
            ) : (
              t("AGREE")
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Resend Invitation */}
      <Dialog open={show.resend} onClose={() => handleShow("resend", false)}>
        <DialogContent>
          Resend Invitation for "{selectedInvitation?.email}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleShow("resend", false)}>
            {t("CANCEL")}
          </Button>
          <Button disabled={loadingBtn} variant="danger" onClick={handleResend}>
            {loadingBtn ? (
              <Spinner animation="border" size="sm" variant="light" />
            ) : (
              "Resend"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Invitation Status */}
      <Dialog open={show.status} onClose={() => handleShow("status", false)}>
        <DialogContent>
          Make Has Registered{" "}
          {!selectedInvitation?.hasRegistered ? "true" : "false"} for "
          {selectedInvitation?.email}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleShow("status", false)}>
            {t("CANCEL")}
          </Button>
          <Button
            disabled={loadingBtn}
            variant="danger"
            onClick={handleEditStatus}
          >
            {loadingBtn ? (
              <Spinner animation="border" size="sm" variant="light" />
            ) : (
              "Edit"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default observer(InvitationList);
