import { makeAutoObservable, runInAction } from "mobx";
import { RegistrationUser } from "../models/User";
import agent from "../api/agent";
import { toast } from "react-toastify";
import {
  DelegationDetails,
  DelegationDriver,
} from "../models/RegisterDelegation";

export const EmptyUser = {
  firstName: "",
  lastName: "",
  birthDate: new Date().toISOString().split("T")[0],
  email: "",
  photoFile: "",
  country: "",
  institution: "",
  jobTitle: "",
  isLeader: false,
  isSpeaker: false,
  isAccredited: false,
  secondRole: "",
  roleId: "",
  securityExtra: "",
  securityWeaponMark: "",
  notesForOrganizer: "",
  securityWeaponNumber: "",
  securityWeaponAmmo: 0,
  shortBio: "",
};

export const INITIAL_DELEGATION = {
  delegationName: "",
  isDriverNeeded: false,
  driverList: [],
  driverNrOfCars: 0,
  isHotelNeeded: undefined,
  hotelName: "",
  arrivalTime: new Date().toISOString().slice(0, 16),
  meanOfTransport: "",
  departureTime: new Date().toISOString().slice(0, 16),
  travelNrOfCars: 0,
  travelCarModel: "",
  travelCarPlates: "",
  personOfContactFirstName: "",
  personOfContactLastName: "",
  personOfContactEmail: "",
  personOfContactPhoneNumber: "",
  arrivingFromCountry: "",
  arrivingFromCity: "",
  countryDepartingTo: "",
  cityDepartingTo: "",
  hasAccommodationArrangements: undefined,
  shortBio: "",
};

export default class RegistrastionsStore {
  users: RegistrationUser[] = [EmptyUser];
  delegationDetails: DelegationDetails = INITIAL_DELEGATION;
  contryList: any[] = [];
  wizard: number = 1;
  error: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get role(): string {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("roleId") || "";
  }

  setDelegationDetails = (state: DelegationDetails) => {
    this.delegationDetails = state;
  };

  resetDelegationInformation = () => {
    this.users = [EmptyUser]
    this.delegationDetails = INITIAL_DELEGATION
    this.wizard = 1
    this.error = false
  };

  listAllContries = async () => {
    const result = await agent.CountriesApi.list();
    runInAction(() => {
      const countryNames: any = [];
      result.map((res: any) => {
        countryNames.push(res.name.common);
      });
      countryNames.sort();
      this.contryList = countryNames;
    });
  };

  addUserToRegistrationList = (roleId: string) => {
    const newUser = EmptyUser;
    newUser.roleId = roleId;

    this.users.push(EmptyUser as RegistrationUser);
  };

  removeUserAt = (index: number) => {
    this.users.splice(index, 1);
  };

  updateUser = (index: number, userData: RegistrationUser) => {
    this.users[index] = userData;
  };

  resetRegistrations = () => {
    this.users = [EmptyUser];
  };

  toggleSpeaker = (index: number) => {
    this.users[index].isSpeaker = !this.users[index].isSpeaker;
  };

  toggleAccreditedMedia = (index: number) => {
    this.users[index].isAccredited = !this.users[index].isAccredited;
  };

  toggleLeader = (index: number) => {
    this.users[index].isLeader = !this.users[index].isLeader;
  };

  setWizard = (index: number) => {
    this.wizard = index;
  };

  setError = (state: boolean) => {
    this.error = state;
  };

  validateFile = (file: File | null) => {
    if (!file) {
      toast.error("Invalid file type. Please choose a valid image file.");
      return false;
    }
    const allowedExtensions = [".jpg", ".jpeg", ".png", ".webp"];
    const fileExtension = file.name.slice(
      ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
    );
    if (!allowedExtensions.includes(`.${fileExtension.toLowerCase()}`)) {
      toast.error("Invalid file type. Please choose a valid image file.");
      return false;
    }
    return true;
  };

  addDriverToDelegation = (driver: DelegationDriver) => {
    runInAction(() => {
      this.delegationDetails.driverList.push(driver);
    });
  };
}
