import { observer } from "mobx-react-lite";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useStore } from "../../../app/stores/store";
import { classNames } from "primereact/utils";
import { emptyHotel } from "../../../app/stores/hotelStore";
import CryptoJS from "crypto-js";

interface Props {
  dialog: boolean;
  hideDialog: () => void;
  decryptor: string;
}

function HotelSave({ dialog, hideDialog, decryptor }: Props) {
  const { hotelStore } = useStore();
  const { createHotel, updateHotel, hotel, setHotel } = hotelStore;
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);

  const decryptInformation = (text: string): string => {
    try {
      if (!text) {
        return "";
      }
      const decrypted = CryptoJS.AES.decrypt(text, decryptor).toString(
        CryptoJS.enc.Utf8
      );
      return decrypted;
    } catch (e) {
      return "";
    }
  };

  const encryptInformation = (text: string): string => {
    return CryptoJS.AES.encrypt(text, decryptor).toString();
  };

  const [hotelName, setHotelName] = useState<string>("");

  useEffect(() => {
    if (decryptor && hotel.name) {
      setHotelName(decryptInformation(hotel.name));
    }
  }, [hotel.name]);

  const saveHotel = async () => {
    setSubmitted(true);
    if (hotel.name.trim()) {
      setLoadingBtn(true);
      if (hotel.id === "") {
        await createHotel(hotel)
          .then(() => {
            toast.success("Hotel Created");
            setHotel(emptyHotel);
          })
          .catch((err) => console.log(err))
          .finally(() => setLoadingBtn(false));
      } else {
        await updateHotel(hotel)
          .then(() => {
            toast.success("Hotel Updated");
          })
          .catch((err) => console.log(err))
          .finally(() => setLoadingBtn(false));
      }
      setSubmitted(false);
      hideDialog();
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setHotelName(value);
    setHotel({ ...hotel, [name]: encryptInformation(value) });
  };

  const dialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        disabled={loadingBtn}
        loading={loadingBtn}
        label="Save"
        icon="pi pi-check"
        onClick={saveHotel}
      />
    </React.Fragment>
  );

  return (
    <Dialog
      visible={dialog}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={hotel.id === "" ? "Create Hotel" : "Update Hotel"}
      modal
      className="p-fluid"
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <label htmlFor="name" className="font-bold">
          Name
        </label>
        <InputText
          value={hotelName}
          name="name"
          onChange={onInputChange}
          required
          autoFocus
          className={classNames({ "p-invalid": submitted && !hotel.name })}
        />
        {submitted && !hotelName && (
          <small className="p-error">Name is required.</small>
        )}
      </div>
    </Dialog>
  );
}
export default observer(HotelSave);
