import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import PageLoader from "../../components/loaders/PageLoader";
import { NotificationList } from "../../../app/models/Notification";
import { Toolbar } from "primereact/toolbar";
import { Image } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import SendNotification from "./SendNotification";
import { Avatar } from "@mui/material";

function Notifications() {
  const { notifyStore } = useStore();
  const { notifyList, selectedNotifications, setSelectedNotifications } =
    notifyStore;
  const [multipleSelectDialog, setMultipleSelectDialog] =
    useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const dt = useRef<DataTable<any[]>>(null);

  useEffect(() => {
    notifyStore.getNotificationList().finally(() => setLoadingInitial(false));
  }, [notifyStore]);

  if (loadingInitial) return <PageLoader />;

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Send Notifications"
          icon="pi pi-plus"
          severity="success"
          onClick={() => setMultipleSelectDialog(true)}
          disabled={!selectedNotifications || !selectedNotifications.length}
        />
      </div>
    );
  };

  const imageBodyTemplate = (rowData: NotificationList) => {
    return <Avatar alt={rowData.firstName} src={rowData.photo} />;
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between mt-2 mb-2">
      <h4 className="m-0">Manage Notifications</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          placeholder="Search..."
          onInput={(e) => {
            const target = e.target as HTMLInputElement;
            setGlobalFilter(target.value);
          }}
        />
      </span>
    </div>
  );

  return (
    <div className="main hotel-list">
      <div className="card">
        <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

        <DataTable
          ref={dt}
          value={notifyList}
          selection={selectedNotifications}
          onSelectionChange={(e: any) => {
            if (Array.isArray(e.value)) {
              setSelectedNotifications(e.value);
            }
          }}
          dataKey="id"
          selectionMode="multiple"
          paginator
          rows={500}
          rowsPerPageOptions={[500, 1000, 1500]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          header={header}
          globalFilter={globalFilter}
        >
          <Column selectionMode="multiple" exportable={false}></Column>
          <Column
            field="photo"
            header="Photo"
            body={imageBodyTemplate}
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="firstName"
            header="First Name"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="lastName"
            header="Last Name"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="email"
            header="Email"
            sortable
            filter={false}
            style={{ minWidth: "16rem" }}
          ></Column>
        </DataTable>
      </div>

      {/* Create & Edit */}
      <SendNotification
        dialog={multipleSelectDialog}
        hideDialog={() => setMultipleSelectDialog(false)}
      />
    </div>
  );
}

export default observer(Notifications);
