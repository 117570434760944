import React from "react";
import { Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

function SuccessRegister() {
  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center pb-5 login"
    >
      <Card
        style={{ width: "800px" }}
        className="newFont prevent-select card-shadow login-card-size login-card"
      >
        <Card.Header>
          <p className="login-card-title">Register Status</p>
        </Card.Header>
        <Card.Body className="success-login-card-padding card-font-size">
          <Card.Title className="d-flex align-center align-items-center">
            <i
              style={{ fontSize: "60px" }}
              className="fa-regular text-success fa-circle-check pe-3"
            />
            <h4 className="successful-registration-text">
              Sucessfully Registered
            </h4>
          </Card.Title>
          <div className="pt-3">
            <span className="pe-2">You have successfully registered</span>
            <Link to="/login">click here to login!</Link>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default SuccessRegister;
