import React, { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import MUIDataTable from "mui-datatables";
import PageLoader from "../../components/loaders/PageLoader";
import { UserHotel } from "../../../app/models/Hotel";
import CryptoJS from "crypto-js";
import KeySettingModal from "../../components/KeySettingModal";

function UserHotelList() {
  const { hotelStore } = useStore();
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const [key, setKey] = useState<string>("");

  useEffect(() => {
    if (key) {
      hotelStore
        .getUserHotels()
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setLoadingInitial(false));
    }
  }, [hotelStore, key]);

  if (loadingInitial && key) return <PageLoader />;

  const decryptInformation = (text: string): string => {
    try {
      if (!text) {
        return "";
      }
      const decrypted = CryptoJS.AES.decrypt(text, key).toString(
        CryptoJS.enc.Utf8
      );
      return decrypted;
    } catch (e) {
      return "";
    }
  };

  const getRoomDetails = (user: UserHotel): string => {
    if (
      user.isHotelNeeded &&
      hotelName(user.hotel) === "No Accomodation Assigned"
    ) {
      return "Room Required To Be Set";
    } else if (
      user.isHotelNeeded &&
      hotelName(user.hotel) !== "No Accomodation Assigned"
    ) {
      const vipNumber =
        user.vipRoomNumber.length > 0
          ? "Vip Room: " +
            user.vipRoomNumber
              .map((roomNumber) => {
                return decryptInformation(roomNumber);
              })
              .join(",")
          : "";
      const nonVipNumber =
        user.nonVipRoomNumber.length > 0
          ? "NonVip Room: " +
            user.nonVipRoomNumber
              .map((roomNumber) => {
                return decryptInformation(roomNumber);
              })
              .join(",")
          : "";
      return vipNumber + nonVipNumber;
    } else if (!user.isHotelNeeded) {
      return "Private";
    }
    return "";
  };

  const hotelName = (hotel: string) => {
    if ("No Accomodation Assigned" === hotel) {
      return hotel;
    } else {
      return decryptInformation(hotel);
    }
  };

  const USER_DATA = hotelStore.userHotelList.map((user) => ({
    delegationName: user.delegationName,
    hotel: hotelName(user.hotel),
    roomNumber: getRoomDetails(user),
    personOfContactFirstName: user.personOfContactFirstName,
    personOfContactLastName: user.personOfContactLastName,
    personOfContactEmail: user.personOfContactEmail,
    personOfContactPhoneNumber: `+${user.personOfContactPhoneNumber}`,
  }));

  const LIST_COLUMNS = [
    { name: "delegationName", label: "Delegation Name" },
    { name: "hotel", label: "Hotel Name" },
    { name: "roomNumber", label: "Room" },
    { name: "personOfContactFirstName", label: "Person Of Contact FirstName" },
    { name: "personOfContactLastName", label: "Person Of Contact LastName" },
    { name: "personOfContactEmail", label: "Person Of Contact Email" },
    {
      name: "personOfContactPhoneNumber",
      label: "Person Of Contact Phone Number",
    },
  ];

  return (
    <div className="main">
      {key ? (
        <MUIDataTable
          title="User Hotel List"
          data={USER_DATA}
          columns={LIST_COLUMNS}
          options={{
            responsive: "vertical",
            viewColumns: true,
            selectableRows: "none",
            rowsPerPage: 500,
            rowsPerPageOptions: [500, 1000, 1500],
            tableBodyHeight: "auto",
          }}
        />
      ) : (
        <></>
      )}

      <KeySettingModal
        show={!key}
        onClick={function (value) {
          setKey(value);
        }}
      />
    </div>
  );
}
export default observer(UserHotelList);
