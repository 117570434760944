import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import { useTranslation } from "react-i18next";
import PageLoader from "../loaders/PageLoader";
import { Drivers as Driver } from "../../../app/models/Drivers";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MUIDataTable from "mui-datatables";
import AddIcon from "@mui/icons-material/Add";
import { observer } from "mobx-react-lite";
import "./driverStyle.scss";
import { Button, Modal, Spinner } from "react-bootstrap";
import CreateDriver from "./CreateDriver";
import EditDriver from "./EditDriver";
import { FaXmark } from "react-icons/fa6";

const Drivers = () => {
  const { driverStore, delegationStore } = useStore();
  const { delegation, setDelegation } = delegationStore;
  const MODAL_VALUES = { create: false, edit: false, dialog: false };
  const [show, setShow] = useState(MODAL_VALUES);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const [selectedDriver, setSelectedDriver] = useState({
    id: "",
    name: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    driverStore
      .getDrivers()
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoadingInitial(false));
  }, [driverStore]);

  if (loadingInitial) return <PageLoader />;

  const handleShow = (action: "create" | "edit" | "dialog", state: boolean) => {
    setShow({ ...show, [action]: state });
  };

  const handleAgree = () => {
    setLoadingBtn(true);
    driverStore
      .deleteDriver(selectedDriver.id)
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        handleShow("dialog", false);
        setLoadingBtn(false);
      });
  };

  const handleOpenEdit = (driver: Driver) => {
    handleShow("edit", true);
    driverStore.selectDriver(driver);
  };

  const formatPhoneNumber = (number: string) => {
    const formattedPhoneNumber = `+${number.substring(0, 3)} ${number.substring(
      3,
      5
    )} ${number.substring(5, 8)} ${number.substring(8)}`;
    return formattedPhoneNumber;
  };

  const DRIVER_DATA = driverStore.drivers.map((c) => {
    return {
      firstName: c.firstName,
      lastName: c.lastName,
      phoneNumber: formatPhoneNumber(c.phoneNumber),
      occupied: c.occupied ? t("YES") : t("NO"),
      action: (
        <div className="d-flex">
          <Tooltip
            onClick={() => {
              setSelectedDriver({
                id: c.id,
                name: c.firstName + " " + c.lastName,
              });
              handleShow("dialog", true);
            }}
            title="Delete"
          >
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            onClick={() => {
              handleOpenEdit(c);
            }}
            title="Edit"
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    };
  });

  const DRIVER_COLUMNS = [
    { name: "firstName", label: t("FIRST_NAME") },
    { name: "lastName", label: t("LAST_NAME") },
    { name: "phoneNumber", label: t("PHONE_NUMBER") },
    { name: "occupied", label: t("OCCUPIED") },
    { name: "action", label: t("ACTION") },
  ];

  return (
    <>
      <div className="main">
        <MUIDataTable
          title={
            <h4>
              {t("DRIVER_LIST")}
              {delegation && (
                <span>
                  {" "}
                  of "{delegation.delegationName}" delegation
                  <Tooltip
                    onClick={() => setDelegation(undefined)}
                    title={"Remove Delegation"}
                  >
                    <IconButton>
                      <FaXmark size={20} color="black" />
                    </IconButton>
                  </Tooltip>
                </span>
              )}
            </h4>
          }
          data={DRIVER_DATA}
          columns={DRIVER_COLUMNS}
          options={{
            responsive: "vertical",
            viewColumns: true,
            selectableRows: "none",
            rowsPerPage: 500,
            rowsPerPageOptions: [500, 1000, 1500],
            tableBodyHeight: "auto",
            customToolbar: () => (
              <>
                <Tooltip
                  onClick={() => handleShow("create", true)}
                  title={t("ADD")}
                >
                  <IconButton>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </>
            ),
          }}
        />
      </div>

      <Dialog open={show.dialog} onClose={() => handleShow("dialog", false)}>
        <DialogContent>
          {t("CONFIRM_DELETE_2")} "{selectedDriver.name}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleShow("dialog", false)}>
            {t("CANCEL")}
          </Button>
          <Button disabled={loadingBtn} variant="danger" onClick={handleAgree}>
            {loadingBtn ? (
              <Spinner animation="border" size="sm" variant="light" />
            ) : (
              t("AGREE")
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* CREATE */}
      <Modal
        onHide={() => handleShow("create", false)}
        show={show.create}
        centered
      >
        <CreateDriver handleCloseDriver={() => handleShow("create", false)} />
      </Modal>

      {/* EDIT */}
      <Modal onHide={() => handleShow("edit", false)} show={show.edit} centered>
        <EditDriver handleCloseDriver={() => handleShow("edit", false)} />
      </Modal>
    </>
  );
};
export default observer(Drivers);
