export interface Hotel {
  id: string;
  name: string;
  rooms?: Room[];
}

export interface Room {
  id: string;
  nr: number;
  type: RoomType;
  occupied: boolean;
  hotelId: string | null;
  delegationId?: string;
}

export interface RoomCreate {
  id: string;
  rooms: string[];
  type: RoomType;
  occupied: boolean;
  hotelId: string | null;
  roomNr?: number;
}

export interface RoomOccupation {
  id: string;
  occupied: boolean;
  delegationId: string;
}

export enum RoomType {
  Vip,
  NonVip,
}

export interface UserHotel {
  delegationId: string;
  delegationName: string;
  hotel: string;
  isHotelNeeded: boolean;
  vipRoomNumber: string[];
  nonVipRoomNumber: string[];
  personOfContactFirstName: string;
  personOfContactLastName: string;
  personOfContactEmail: string;
  personOfContactPhoneNumber: string;
}
