import QRCode from "react-qr-code";
import { useStore } from "../../app/stores/store";
import "./ticketsStyle.scss";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import PageLoader from "../components/loaders/PageLoader";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

function Tickets() {
  const { t } = useTranslation();
  const { userStore, delegationStore } = useStore();
  const { user } = userStore;
  const { setDelegation, delegation } = delegationStore;
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    delegationStore
      .getDelegationFromUser()
      .catch((err: any) => console.log(err.response))
      .finally(() => setLoadingInitial(false));
    return setDelegation(undefined);
  }, [delegationStore]);

  if (loadingInitial) return <PageLoader />;

  function DelegationInformation() {
    setLoadingBtn(true);
    delegationStore
      .sendDelegationInfo()
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        toast.success("Email successfully sent!");
        setLoadingBtn(false);
        handleClose();
      });
  }

  function fromatDate(date: dayjs.Dayjs | Date | string) {
    return dayjs(date).format("DD-MM-YYYY");
  }

  function formatPhoneNumber(number: string) {
    const formattedPhoneNumber = `+${number.substring(0, 3)} ${number.substring(
      3,
      5
    )} ${number.substring(5, 8)} ${number.substring(8)}`;
    return formattedPhoneNumber;
  }

  return (
    <div className="main ticket-background-color">
      <Container className="py-4">
        <Row className="user-profile">
          <Col lg={5} className="mt-2">
            <Card className="shadow-sm text-center">
              <Card.Header className="bg-transparent">
                <img className="profile_img" src={user?.photo} alt="img" />
                <h3>
                  {user?.firstName} {user?.lastName}
                </h3>
              </Card.Header>
              <Card.Body>
                <p className="mb-0">
                  <strong className="pr-1">Role:</strong> {user?.userRoles}
                </p>
                <p className="mb-0">
                  <strong className="pr-1">Email:</strong> {user?.email}
                </p>
                {delegation && (
                  <button
                    onClick={handleOpen}
                    disabled={loadingBtn}
                    className="btn btn-primary mt-2"
                  >
                    {loadingBtn ? (
                      <Spinner animation="border" size="sm" variant="light" />
                    ) : (
                      <>Request Accommodation Info!</>
                    )}
                  </button>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col lg={2}></Col>
          <Col lg={5} className="mt-2">
            <Card className="shadow-sm qr-card-box">
              <Card.Header className="bg-transparent border-0">
                <h3 className="mb-0 hide-qr">
                  <i className="far fa-clone pr-1 "></i>QR CODE
                </h3>
              </Card.Header>
              <Card.Body className="pt-0 ticket-container">
                <div className="ticket-box ">
                  <h2>{t("TICKET")}</h2>
                  <div className="qrcode mt-2">
                    <QRCode value={user?.id ? user.id : ""} />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="user-profile mt-5">
          <Col lg={6}>
            <Card className="shadow-sm user-info-top">
              <Card.Header className="bg-transparent border-0">
                <h3 className="mb-0">
                  <i className="far fa-clone pr-1"></i>User Information
                </h3>
              </Card.Header>
              <Card.Body className="pt-0">
                <table className="table table-bordered mb-0 table-width">
                  <tbody>
                    <tr>
                      <th>Attended:</th>
                      <td width="2%">:</td>
                      <td>{user?.hasAttended ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <th>Is Speaker</th>
                      <td width="2%">:</td>
                      <td>{user?.isSpeaker ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <th>Birthdate</th>
                      <td width="2%">:</td>
                      <td>{fromatDate(user!.birthDate)}</td>
                    </tr>
                    <tr>
                      <th>Country</th>
                      <td width="2%">:</td>
                      <td>{user?.country}</td>
                    </tr>
                    <tr>
                      <th>Institution</th>
                      <td width="2%">:</td>
                      <td>{user?.institution}</td>
                    </tr>
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </Col>
          {delegation && (
            <Col lg={6}>
              <Card className="shadow-sm user-info-top">
                <Card.Header className="bg-transparent border-0">
                  <h3 className="mb-0">
                    <i className="far fa-clone pr-1"></i>Information
                  </h3>
                </Card.Header>
                <Card.Body className="pt-0">
                  <table className="table table-bordered mb-0 table-width">
                    <tbody>
                      {delegation.delegationName && (
                        <>
                          <tr>
                            <th>Name:</th>
                            <td width="2%">:</td>
                            <td>{delegation.delegationName}</td>
                          </tr>
                        </>
                      )}
                      <tr>
                        <th>Arrival / Departure</th>
                        <td width="2%">:</td>
                        <td>{`${fromatDate(
                          delegation.arrivalTime
                        )} / ${fromatDate(delegation.departureTime)}`}</td>
                      </tr>
                      {delegation.isDriverNeeded && (
                        <tr>
                          <th>Cars needed?</th>
                          <td width="2%">:</td>
                          <td>{delegation.driverNrOfCars}</td>
                        </tr>
                      )}
                      {/* {delegation.isHotelNeeded ? (
                        <tr>
                          <th>Rooms needed?</th>
                          <td width="2%">:</td>
                          <td>{delegation.hotelNrOfRooms}</td>
                        </tr>
                      ) : (
                        <tr>
                          <th>Hotel</th>
                          <td width="2%">:</td>
                          <td>{delegation.hotelName}</td>
                        </tr>
                      )} */}
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>
        {delegation && (
          <Row className="user-profile mt-5">
            <Col lg={6}>
              <Card className="shadow-sm user-info-top">
                <Card.Header className="bg-transparent border-0">
                  <h3 className="mb-0">
                    <i className="far fa-clone pr-1"></i>Travel Details
                  </h3>
                </Card.Header>
                <Card.Body className="pt-0">
                  <table className="table table-bordered mb-0 table-width">
                    <tbody>
                      <tr>
                        <th>Means of Transport:</th>
                        <td width="2%">:</td>
                        <td>{delegation.meanOfTransport}</td>
                      </tr>
                      <tr>
                        <th>Car Model:</th>
                        <td width="2%">:</td>
                        <td>{delegation.travelCarModel}</td>
                      </tr>
                      <tr>
                        <th>Car Plates:</th>
                        <td width="2%">:</td>
                        <td>{delegation.travelCarPlates}</td>
                      </tr>
                      <tr>
                        <th>Nr. of Cars:</th>
                        <td width="2%">:</td>
                        <td>{delegation.travelNrOfCars}</td>
                      </tr>
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
            </Col>
            {delegation.personOfContactFirstName && (
              <>
                <Col lg={6}>
                  <Card className="shadow-sm user-info-top">
                    <Card.Header className="bg-transparent border-0">
                      <h3 className="mb-0">
                        <i className="far fa-clone pr-1"></i>Person of Contact
                      </h3>
                    </Card.Header>
                    <Card.Body className="pt-0">
                      <table className="table table-bordered mb-0 table-width">
                        <tbody>
                          <tr>
                            <th>Full Name:</th>
                            <td width="2%">:</td>
                            <td>{`${delegation.personOfContactFirstName} ${delegation.personOfContactLastName}`}</td>
                          </tr>
                          <tr>
                            <th>Email:</th>
                            <td width="2%">:</td>
                            <td>{delegation.personOfContactEmail}</td>
                          </tr>
                          <tr>
                            <th>Phone Number:</th>
                            <td width="2%">:</td>
                            <td>
                              {formatPhoneNumber(
                                delegation.personOfContactPhoneNumber
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Card.Body>
                  </Card>
                </Col>
              </>
            )}
          </Row>
        )}
      </Container>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Accommodation Info Request</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to send the email?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="btn btn-danger">
            Cancel
          </button>
          <Button
            disabled={loadingBtn}
            onClick={DelegationInformation}
            color="primary"
            autoFocus
          >
            {loadingBtn ? (
              <Spinner animation="border" size="sm" variant="light" />
            ) : (
              <>Send!</>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default observer(Tickets);
