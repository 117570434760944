import { makeAutoObservable, runInAction } from "mobx";
import { LoginHistory } from "../models/User";
import agent from "../api/agent";

export default class LoginHistoryStore {
  loginHistoryRegistry = new Map<string, LoginHistory>();

  constructor() {
    makeAutoObservable(this);
  }

  get loginHistory() {
    return Array.from(this.loginHistoryRegistry.values());
  }

  loginHistoryList = async () => {
    const result = await agent.Account.userLoginInfo();
    runInAction(() => {
      result.forEach((history) => {
        this.loginHistoryRegistry.set(history.id, history);
      });
    });
  };
}
