import { observer } from "mobx-react-lite";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useStore } from "../../../app/stores/store";
import { classNames } from "primereact/utils";
import { emptyHotel } from "../../../app/stores/hotelStore";

interface Props {
  dialog: boolean;
  hideDialog: () => void;
}

function SendNotification({ dialog, hideDialog }: Props) {
  const { notifyStore } = useStore();
  const { sendNotification } = notifyStore;
  const INITIAL_STATE = {
    title: "",
    body: "",
  };
  const [message, setMessage] = useState(INITIAL_STATE);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);

  const handleSubmit = async () => {
    setSubmitted(true);
    if (message.title.trim() && message.body.trim()) {
      setLoadingBtn(true);

      await sendNotification(message)
        .then(() => {
          toast.success("Notifications Sent");
          setMessage(INITIAL_STATE);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoadingBtn(false));

      setSubmitted(false);
      hideDialog();
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setMessage({ ...message, [name]: value });
  };

  const dialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        disabled={loadingBtn}
        loading={loadingBtn}
        label="Send"
        icon="pi pi-check"
        onClick={handleSubmit}
      />
    </React.Fragment>
  );

  return (
    <Dialog
      visible={dialog}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Send Notifications"
      modal
      className="p-fluid"
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <label htmlFor="title" className="font-bold">
          Title
        </label>
        <InputText
          value={message.title}
          name="title"
          onChange={onInputChange}
          required
          autoFocus
          className={classNames({ "p-invalid": submitted && !message.title })}
        />
        {submitted && !message.title && (
          <small className="p-error">Title is required.</small>
        )}
      </div>
      <div className="field">
        <label htmlFor="body" className="font-bold">
          Body
        </label>
        <InputText
          value={message.body}
          name="body"
          onChange={onInputChange}
          required
          autoFocus
          className={classNames({ "p-invalid": submitted && !message.body })}
        />
        {submitted && !message.body && (
          <small className="p-error">Body is required.</small>
        )}
      </div>
    </Dialog>
  );
}
export default observer(SendNotification);
