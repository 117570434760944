import { TextField } from "@mui/material";
import { Dayjs } from "dayjs";
import { ChangeEventHandler } from "react";

interface Props {
  value: string | number | boolean | Dayjs | Date;
  onChange:
    | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  name: string;
  type?: string;
  label?: string;
  required?: boolean;
  error?: boolean;
  multiline?: boolean;
  title?: string;
}

function CustomTextField({
  value,
  onChange,
  name,
  type = "text",
  label,
  required = true,
  error,
  multiline = false,
  title = ''
}: Props) {
  return (
    <>
      <TextField
        value={value ? value : ""}
        onChange={onChange}
        name={name}
        title={title}
        type={type}
        label={label}
        fullWidth
        variant="outlined"
        error={error && !value}
        required={required}
        multiline={multiline}
      />
    </>
  );
}
export default CustomTextField;
