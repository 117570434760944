import { FormControl, InputLabel, Select } from "@mui/material";
import { isObject } from "lodash";
import { SelectArray } from "../../app/models/DelegationRoles";

interface Props {
  value: string | number;
  name?: string;
  onChange: any;
  required?: boolean;
  label: string;
  options: string[] | number[] | SelectArray[];
  error?: boolean;
  title?: string;
}

function CustomSelect({
  value,
  name,
  onChange,
  required = true,
  label,
  options,
  error,
  title = '',
}: Props) {
  return (
    <FormControl fullWidth>
      <InputLabel id="custom-select-label">
        {label}
        {required && " *"}
      </InputLabel>
      <Select
        native
        labelId="custom-select-label"
        id="custom-select"
        value={value}
        onChange={onChange}
        required={required}
        name={name}
        title={title}
        error={error && !value}
      >
        {options.map((option, index) => {
          if (isObject(option))
            return (
              <option key={index} value={option.value}>
                {option.name}
              </option>
            );
          else
            return (
              <option key={index} value={option}>
                {option}
              </option>
            );
        })}
      </Select>
    </FormControl>
  );
}
export default CustomSelect;
