import { toast } from "react-toastify";
import axios, { AxiosError, AxiosResponse } from "axios";
import { store } from "../stores/store";
import {
  EmailActive,
  LoginHistory,
  PassportSent,
  User,
  UserFormValues,
  UserFormValuesQr,
} from "../models/User";
import { EmailConfirmation } from "../models/EmailConfirmation";
import { Agenda } from "../models/Agenda";
import { Ticket } from "../models/Ticket";
import { InvitationEmails, InvitationStatus } from "../models/InvitationLinks";
import i18n from "i18next";
import { InvitationList } from "../models/InvitationList";
import { Hotel, RoomCreate, RoomOccupation, UserHotel } from "../models/Hotel";
import { Drivers as Driver } from "../models/Drivers";
import {
  NotificationHistory,
  NotificationList,
  SendNotification,
} from "../models/Notification";
import { DelegationList, DelegationUserMoving } from "../models/DelegationList";

const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

const handleErrorResponse = (data: any) => {
  if (data) {
    if (data === "InvalidToken") {
      store.userStore.logout();
      toast.error("Session Expired");
    } else {
      const errorMessage = i18n.t(data);
      toast.error(errorMessage);
    }
  }
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
  const token = store.commonStore.token;
  if (token) config.headers!.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    // todo: create enum for string types that are used as constants
    if (process.env.NODE_ENV === "development") await sleep(300);
    return response;
  },
  (error: AxiosError) => {
    if (error.message === "Network Error") {
      toast.error("Database is updating.");
    } else {
      const { data, status, headers } = error.response!;
      switch (status) {
        case 400:
          if (typeof data.errors === "string") {
            handleErrorResponse(data.errors);
          } else {
            for (const key in data.errors) {
              if (data.errors[key]) {
                if (Array.isArray(data.errors[key])) {
                  data.errors[key].forEach((err: any) => {
                    handleErrorResponse(err);
                  });
                } else {
                  handleErrorResponse(data.errors[key]);
                }
              }
            }
          }
          break;
        case 401:
          if (
            status === 401 &&
            headers["www-authenticate"]?.startsWith(
              'Bearer error="invalid_token"'
            )
          ) {
            store.userStore.logout();
            toast.error("Session expired - please login again");
          }
          break;
        case 404:
          // history.push("/not-found");
          toast.error("Resource not found");
          break;
        case 500:
          store.commonStore.setServerError(data);
          toast.error("Server Error");
          break;
        case 503:
          toast.error("Database is not available");
          break;
      }
    }
    return Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: any, config?: any) =>
    axios.post<T>(url, body, config).then(responseBody),
  put: <T>(url: string, body: any, config?: any) =>
    axios.put<T>(url, body, config).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Account = {
  current: () => requests.get<User>("/account"),
  login: (user: UserFormValues) => requests.post<User>("/account/login", user),
  requestPasswordChange: (email: string) =>
    requests.post<User>("/account/password/change/request", { email }),
  sendCodeVerificationForEmailChange: (data: { code: string; email: string }) =>
    requests.post<User>("/account/code/verify", data),
  changePassword: (data: { code: string; email: string; password: string }) =>
    requests.post<User>("/account/change/password", data),
  register: (user: FormData) =>
    requests.post<User>("/account/register", user, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
  list: () => requests.get<User[]>("/account/all"),
  listAmbassadors: () => requests.get<User[]>("/account/ambassadors"),
  listMedia: () => requests.get<User[]>("/account/media"),
  listAudience: () => requests.get<User[]>("/account/audience"),
  verifyEmail: (token: string, email: string) =>
    requests.post<void>(
      `/account/verifyEmail?token=${token}&email=${email}`,
      {}
    ),
  resendEmailConfirm: (email: string) =>
    requests.get(`/account/resendEmailConfirmationLink?email=${email}`),
  delete: (id: string) => axios.delete<void>(`/account/${id}`),
  userLoginInfo: () => requests.get<LoginHistory[]>("/account/userLogin"),
  activateEmail: (active: EmailActive) =>
    requests.post<EmailActive>("/account/activateEmail", active),
  updatePassportStatus: (active: PassportSent) =>
    requests.put<PassportSent>("/account/update/passport/status", active),
};

const DelegationApi = {
  list: () => requests.get<DelegationList[]>("/delegation"),
  getSpeakers: () => requests.get<DelegationList[]>("/delegation/speakers"),
  getDelegationFromUser: () => requests.get<DelegationList>("/delegation/user"),
  registerDelegation: (user: FormData) =>
    requests.post<void>("/delegation/registerDelegation", user, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
  moveMemberToAnotherDelegation: (data: DelegationUserMoving) =>
    requests.post<void>("/delegation/move-member", data),
  sendInfo: () => requests.get<void>("/delegation/sendInfo"),
};

const TicketApi = {
  validate: (ticket: Ticket) => requests.post<void>("Ticket", ticket),
  attendance: (ticket: Ticket) =>
    requests.post<void>("Ticket/attendance", ticket),
};

const Agendas = {
  list: () => requests.get<Agenda[]>("/agenda"),
  details: (id: string) => requests.get<Agenda>(`/agenda/${id}`),
  create: (agenda: Agenda) => requests.post<Agenda>("/agenda", agenda),
  edit: (agenda: Agenda) =>
    requests.put<Agenda>(`/agenda/${agenda.id}`, agenda),
  delete: (id: string) => requests.del<Agenda>(`/agenda/${id}`),
};

const Invitation = {
  list: () => requests.get<InvitationList[]>("/invitation"),
  create: (invitation: InvitationList) =>
    requests.post<void>("/invitation", invitation),
  send: (invitation: InvitationEmails) =>
    requests.post("/invitation/send", invitation),
  editStatus: (status: InvitationStatus) =>
    requests.put<void>(`/invitation/status/${status.id}`, status),
  delete: (id: string) => requests.del<void>(`/invitation/${id}`),
};

const CountriesApi = {
  list: () => requests.get<any[]>("https://restcountries.com/v3.1/all"),
};

const HotelApi = {
  list: () => requests.get<Hotel[]>("/hotel"),
  create: (hotel: Hotel) => requests.post<void>("/hotel", hotel),
  edit: (hotel: Hotel) => requests.put<Agenda>(`/hotel/${hotel.id}`, hotel),
  delete: (id: string) => requests.del<void>(`/hotel/${id}`),
  createRoom: (room: RoomCreate) => requests.post<Hotel>("/hotel/room", room),
  occupyRoom: (room: RoomOccupation) =>
    requests.post<Hotel>("/hotel/occupation", room),
  deleteRoom: (id: string) => requests.del<string>(`/hotel/room/${id}`),
  userHotel: () => requests.get<UserHotel[]>("/hotel/userHotel"),
};

const Drivers = {
  list: () => requests.get<Driver[]>("/driver"),
  create: (driver: Driver) => requests.post<void>("/driver", driver),
  edit: (driver: Driver) => requests.put(`/driver/${driver.id}`, driver),
  delete: (id: string) => requests.del<string>(`/driver/${id}`),
};

const FirebaseApi = {
  list: () => requests.get<NotificationList[]>("/notify"),
  listHistory: () => requests.get<NotificationHistory[]>("/notify/history"),
  send: (data: SendNotification) =>
    requests.post<void>("/firebase/send-notification", data),
};

const agent = {
  Account,
  Agendas,
  TicketApi,
  Invitation,
  CountriesApi,
  HotelApi,
  Drivers,
  FirebaseApi,
  DelegationApi,
};

export default agent;
