import React, { useEffect } from "react";
import { Modal, Button, Spinner, Form, Row, Col } from "react-bootstrap";
import { Agenda, Halls } from "../../../app/models/Agenda";
import dayjs from "dayjs";
import { useState } from "react";
import { useStore } from "../../../app/stores/store";
import "./agendaStyle.scss";
import {
  Autocomplete,
  Select,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

interface CreateAgendaProps {
  handleCloseAgenda: () => void;
}

function CreateAgenda({ handleCloseAgenda }: CreateAgendaProps) {
  const AGENDA_VALUES: Agenda = {
    id: "",
    nrRendor: 0,
    fromTime: dayjs(new Date("2024-04-11 10:00")),
    endingTime: dayjs(new Date("2024-04-11 10:00")),
    speaker: "",
    description: "",
    hallNumber: 1,
  };

  const { agendaStore, manageUsersStore } = useStore();
  const [agenda, setAgenda] = useState<Agenda>(AGENDA_VALUES);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loadingIntital, setLoadingInitial] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    manageUsersStore.loadAllUsers().finally(() => setLoadingInitial(false));
  }, [manageUsersStore]);

  function handleInputChange(e: any): void {
    const { name, value } = e.target;
    setAgenda({ ...agenda, [name]: value });
  }

  async function handleSubmit(
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> {
    setLoadingBtn(true);
    e.preventDefault();
    await agendaStore
      .createAgenda(agenda)
      .then(() => {
        setAgenda(AGENDA_VALUES);
        handleCloseAgenda();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingBtn(false);
      });
  }

  const speakerNames: string[] = manageUsersStore.getAllUsers
    .filter((user) => user.isSpeaker && !user.isDeleted)
    .map((speaker) => `${speaker.firstName} ${speaker.lastName}`.toUpperCase());

  return (
    <div className="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title>{t("CREATE_AGENDA")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="row-agenda pt-3">
            <Col md={6}>
              <TextField
                fullWidth
                label={t("SERIAL_NUMBER")}
                variant="outlined"
                type="number"
                name="nrRendor"
                value={agenda.nrRendor}
                required
                onChange={handleInputChange}
              />
            </Col>
            <Col md={6}>
              <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{t("HALL_NUMBER")}</InputLabel>
                <Select
                  fullWidth
                  label={t("HALL_NUMBER")}
                  variant="outlined"
                  name="hallNumber"
                  value={agenda.hallNumber}
                  required
                  onChange={handleInputChange}
                >
                  {Halls.map((hall) => (
                    <MenuItem key={hall.value} value={hall.value}>
                      {hall.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col md={6}>
              <TextField
                fullWidth
                label={t("FROM_TIME")}
                variant="outlined"
                type="datetime-local"
                name="fromTime"
                required
                value={dayjs(agenda.fromTime).format("YYYY-MM-DDTHH:mm")}
                onChange={handleInputChange}
              />
            </Col>
            <Col md={6}>
              <TextField
                fullWidth
                label={t("ENDING_TIME")}
                variant="outlined"
                type="datetime-local"
                name="endingTime"
                required
                value={dayjs(agenda.endingTime).format("YYYY-MM-DDTHH:mm")}
                onChange={handleInputChange}
              />
            </Col>
            <Col md={12}>
              <Autocomplete
                disablePortal
                options={speakerNames}
                disabled={loadingIntital}
                onChange={(e, newValue: any) =>
                  setAgenda({ ...agenda, speaker: newValue })
                }
                value={agenda.speaker}
                isOptionEqualToValue={(option, value) => {
                  return value === "" || option === value;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("SPEAKER")}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Col>
            <Col md={12}>
              <TextField
                fullWidth
                label={t("DESCRIPTION")}
                variant="outlined"
                multiline
                rows={3}
                name="description"
                value={agenda.description}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <div className="mt-3 gap-2 d-flex">
            <Button disabled={loadingBtn} type="submit" variant="success">
              {loadingBtn ? (
                <Spinner animation="border" size="sm" variant="light" />
              ) : (
                t("CREATE")
              )}
            </Button>
            <Button variant="danger" onClick={handleCloseAgenda}>
              {t("CLOSE")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </div>
  );
}

export default observer(CreateAgenda);
