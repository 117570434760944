import { observer } from "mobx-react-lite";
import { Dialog } from "primereact/dialog";
import { useStore } from "../../../app/stores/store";
import React, { useState } from "react";
import { Button } from "primereact/button";
import { toast } from "react-toastify";

interface Props {
  deleteDialog: boolean;
  hideDeleteDialog: () => void;
  multipleDeleteDialog: boolean;
  hideDeleteMultipleDialog: () => void;
}

function RoomDelete({
  deleteDialog,
  hideDeleteDialog,
  multipleDeleteDialog,
  hideDeleteMultipleDialog,
}: Props) {
  const { roomStore } = useStore();
  const { room, selectedRooms, deleteRoom } = roomStore;
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);

  const deleteSelectedRooms = async () => {
    setLoadingBtn(true);
    for (const room of selectedRooms) {
      await deleteRoom(room.id);
    }
    setLoadingBtn(false);
    hideDeleteMultipleDialog();
    toast.success("Rooms Deleted");
  };

  const delRoom = async () => {
    setLoadingBtn(true);
    await deleteRoom(room.id)
      .then(() => {
        toast.success("Room Deleted");
        hideDeleteDialog();
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingBtn(false));
  };

  const deleteDialogFooter = (multiple: boolean) => {
    return (
      <React.Fragment>
        <Button
          label="No"
          icon="pi pi-times"
          outlined
          onClick={multiple ? hideDeleteMultipleDialog : hideDeleteDialog}
        />
        <Button
          label="Yes"
          disabled={loadingBtn}
          loading={loadingBtn}
          icon="pi pi-check"
          severity="danger"
          onClick={multiple ? deleteSelectedRooms : delRoom}
        />
      </React.Fragment>
    );
  };

  return (
    <>
      <Dialog
        visible={deleteDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteDialogFooter(false)}
        onHide={hideDeleteDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {room && (
            <span>Are you sure you want to delete this room?</span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={multipleDeleteDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteDialogFooter(true)}
        onHide={hideDeleteMultipleDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {room && (
            <span>Are you sure you want to delete the selected rooms?</span>
          )}
        </div>
      </Dialog>
    </>
  );
}
export default observer(RoomDelete);
