import { makeAutoObservable, runInAction } from "mobx";
import { Agenda } from "../models/Agenda";
import agent from "../api/agent";
import { v4 as uuid } from "uuid";

export default class AgendaStore {
  agendaRegistry = new Map<string, Agenda>();
  selectedAgenda?: Agenda;
  loadingAgenda = true;

  constructor() {
    makeAutoObservable(this);
  }

  get agendas() {
    return Array.from(this.agendaRegistry.values());
  }

  selectAgend = (agenda: Agenda) => {
    this.selectedAgenda = agenda;
  };

  getAgenda = async () => {
    this.loadingAgenda = true;
    const result = await agent.Agendas.list();
    runInAction(() => {
      result.forEach((agenda) => {
        this.agendaRegistry.set(agenda.id, agenda);
      });
      this.loadingAgenda = false;
    });
  };

  getAgendaById = async (id: string) => {
    const result = await agent.Agendas.details(id);
    runInAction(() => {
      this.selectedAgenda = result;
    });
  };

  createAgenda = async (agenda: Agenda) => {
    agenda.id = uuid();
    await agent.Agendas.create(agenda);
    runInAction(() => {
      this.agendaRegistry.set(agenda.id, agenda);
    });
  };

  updateAgenda = async (agenda: Agenda) => {
    await agent.Agendas.edit(agenda);
    runInAction(() => {
      this.agendaRegistry.set(agenda.id, agenda);
    });
  };

  deleteAgenda = async (id: string) => {
    await agent.Agendas.delete(id);
    runInAction(() => {
      this.agendaRegistry.delete(id);
    });
  };
}
