const sidebarStylization = (sidebarCollapsed: boolean) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: sidebarCollapsed ? "transparent" : "#333",
    paddingLeft: sidebarCollapsed ? "" : "40px",
    transition: "all 0.3s ease",
  };
};

export { sidebarStylization };
