import dayjs from "dayjs";

export enum Roles {
  Administrator = "Administrator",
  Audience = "Audience",
  Delegacion = "Delegacion",
  Media = "Media",
  ForumSpeaker = "ForumSpeaker",
}

export interface UserBase {
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
  birthDate: dayjs.Dayjs | Date | string;
}

export interface User extends UserBase {
  id: string;
  token: string;
  userRoles: Roles[];
  hasAttended: boolean;
  isAccredited: boolean;
  shortBio: string;
  emailConfirmed: boolean;
  isDeleted: boolean;
  photo: string;
  hasSentPassport: boolean | null;
  country: string;
  institution: string;
  isSpeaker: boolean;
  delegationId?: string;
  securityExtra?: string;
  securityWeaponMark?: string;
  securityWeaponNumber?: string;
  securityWeaponAmmo?: number;
}

export interface RegistrationUser extends UserBase {
  isSpeaker: boolean;
  notesForOrganizer: string
  shortBio: string
  country: string;
  jobTitle: string;
  institution: string;
  isLeader: boolean;
  isAccredited: boolean;
  secondRole: number | string;
  photoFile: File | any;
  securityExtra: string;
  securityWeaponMark: string;
  securityWeaponNumber: string;
  securityWeaponAmmo: number;
}

export interface UserFormValues {
  email: string;
  firstName?: string;
  lastName?: string;
}

export interface UserFormValuesQr {
  email: string;
  firstName: string;
  lastName: string;
  birthDate: dayjs.Dayjs | Date;
}

export interface LoginHistory {
  id: string;
  userEmail: string;
  loginTime: dayjs.Dayjs | Date;
  userIpAddress: string;
}

export interface EmailActive {
  userId: string;
  emailConfirmed: boolean;
}
export interface PassportSent {
  userId: string;
  PassportStatus: boolean;
}
