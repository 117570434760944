import { useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props {
  role: string;
  name: string;
}

function InvitationCards({ role, name }: Props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const { t } = useTranslation();

  const handleCopyLink = (role: string) => {
    const url = window.location.href;
    let baseUrl = url.substring(0, url.lastIndexOf("/"));
    baseUrl += `/register?roleId=${role}`;
    navigator.clipboard.writeText(baseUrl).then(() => {
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 2000); // Hide tooltip after 2 seconds
    });
  };

  return (
    <div className="invitation-links">
      <div className="invitation-title">
        <p>{name}</p>
      </div>
      <OverlayTrigger
        overlay={<Tooltip id="tooltip-disabled">{t("LINK_COPY")}</Tooltip>}
        show={showTooltip}
        placement="bottom"
      >
        <Button onClick={() => handleCopyLink(role)} variant="primary">
          {t("GET_LINK")}
        </Button>
      </OverlayTrigger>
    </div>
  );
}
export default InvitationCards;
