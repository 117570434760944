import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import UserStore from "./userStore";
import AgendaStore from "./agendaStore";
import ManageUsersStore from "./manageUsersStore";
import RegistrastionsStore from "./registrationsStore";
import LoginHistoryStore from "./loginHistoryStore";
import InvitationsStore from "./invitationsStore";
import HotelStore from "./hotelStore";
import RoomStore from "./roomStore";
import DriverStore from "./driverStore";
import NotifyStore from "./notifyStore";
import DelegationStore from "./delegationStore";

interface Store {
  commonStore: CommonStore;
  userStore: UserStore;
  registrationsStore: RegistrastionsStore;
  agendaStore: AgendaStore;
  manageUsersStore: ManageUsersStore;
  loginHistoryStore: LoginHistoryStore;
  invitationsStore: InvitationsStore;
  hotelStore: HotelStore;
  roomStore: RoomStore;
  driverStore: DriverStore;
  notifyStore: NotifyStore;
  delegationStore: DelegationStore;
}

export const store: Store = {
  commonStore: new CommonStore(),
  userStore: new UserStore(),
  agendaStore: new AgendaStore(),
  registrationsStore: new RegistrastionsStore(),
  manageUsersStore: new ManageUsersStore(),
  loginHistoryStore: new LoginHistoryStore(),
  invitationsStore: new InvitationsStore(),
  hotelStore: new HotelStore(),
  roomStore: new RoomStore(),
  driverStore: new DriverStore(),
  notifyStore: new NotifyStore(),
  delegationStore: new DelegationStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
