interface Props {
  mTop?: number;
  mBottom?: number;
}

function ComponentLoader({ mTop = 0, mBottom = 0 }: Props) {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div
        className="componentSpinner"
        style={{ marginTop: mTop, marginBottom: `${mBottom} !important` }}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}
export default ComponentLoader;
