import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { v4 as uuid } from "uuid";
import { InvitationList } from "../models/InvitationList";
import { InvitationStatus } from "../models/InvitationLinks";
import { inviteLinkToNumber } from "../../utilities/constGroup";

export default class InvitationsStore {
  invitationRegistry = new Map<string, InvitationList>();

  constructor() {
    makeAutoObservable(this);
  }

  get invitationList() {
    return Array.from(this.invitationRegistry.values());
  }

  getInvitationList = async () => {
    const result = await agent.Invitation.list();
    runInAction(() => {
      result.forEach((invitation) => {
        this.invitationRegistry.set(invitation.id, invitation);
      });
    });
  };

  createInvitation = async (invitation: InvitationList) => {
    invitation.id = uuid();
    await agent.Invitation.create(invitation);
    runInAction(() => {
      invitation.role = inviteLinkToNumber(invitation.roleId);
      this.invitationRegistry.set(invitation.id, invitation);
    });
  };

  editInvitationStatus = async (status: InvitationStatus) => {
    await agent.Invitation.editStatus(status);
    runInAction(() => {
      const invitation = this.invitationRegistry.get(status.id);
      if (invitation) {
        invitation.hasRegistered = status.hasRegistered;
      }
    });
  };

  deleteInvitation = async (id: string) => {
    await agent.Invitation.delete(id);
    runInAction(() => {
      this.invitationRegistry.delete(id);
    });
  };
}
