export enum InvitationLinks {
  Audience = "a2581c17-6698-41e7-85ac-cf3c9a0fca2d",
  Delegation = "d8fd1139-83b1-40c2-8112-ddd8b66e74d5",
  Media = "f102a454-1966-4a30-92ce-c9e41478ac2e",
  Speaker = "f102a454-1966-4a30-92ce-c9e41478ac25",
  Ambassador = "eae61f6e-38cc-42ab-b23d-d3f272c60475",
}

export interface InvitationEmails {
  emails: string[];
  roleId: string;
}

export interface InvitationStatus {
  id: string;
  hasRegistered: boolean;
}
