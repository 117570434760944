export const quotes = [
  {
    img: "vjosa_osmani.jpg",
    body: "“This year’s theme, Building Alliances for Sustainable Peace is about bringing nations together to ensure that we share the challenges in implementing this agenda, but also find ways in which we can <strong>build new partnerships to not only ensure we move forward collectively, but also sustainably</strong>. To ensure that change is not only positive, but permanent.”",
    footer:
      "President of the Republic of Kosovo, H.E. Dr. Vjosa Osmani Sadriu Host of the International WPS Forum",
  },
  {
    img: "glauk_konjufca.jpg",
    body: "“I am convinced that only by working together and joining forces to fight a discriminative public discourse towards women that normally prevails in historically patriarchal societies, we can create a better world for women and girls.”",
    footer:
      "Speaker of the Parliament of Republic of Kosovo, H.E. Glauk Konjufca",
  },
  {
    img: "albin_kurti.jpg",
    body: "“Inclusion means that women contribute, but participation means they also benefit. Women contribute, so they should also benefit. <strong>Gender equality means liberation, not just integration; it means participation, not just inclusion</strong>. If women have no voice in the institutional response to all threats to human security, the response will also be half-hearted and unbalanced.”",
    footer: "Prime Minister of the Republic of Kosovo, H.E. Albin Kurti",
  },
  {
    img: "donika_gervalla.jpg",
    body: "“Women still have to be better, have to work harder to succeed. Mobilizing the great potential of women is about equality and is about quality, because women bring a different much needed quality to the table.”",
    footer:
      "Deputy Prime Minister and Minister of Foreign Affairs and Diaspora of the Republic of Kosovo, H.E. Donika Gërvalla – Schwarz Co-Host of the International WPS Forum",
  },
  {
    img: "antony_blinken.jpg",
    body: "“Secretary Albright fought for engaging women in all aspects of peacebuilding and security decision-making in part because she’d seen the difference it made when they were given a voice. Whether those women were peace negotiators or peacekeepers, humanitarian workers or human rights defenders, whether they worked in diplomacy or defense, <strong>the process and outcomes were better for their participation</strong>.” – <span>Antony Blinken, U.S. Secretary of State</span>",
  },
  {
    img: "amanda_sloat.jpg",
    body: "“As Russia continues to wage its unprovoked and senseless war in Ukraine, we all have to ample reason to Ms. Secretary’s Albright <strong>diplomatic mind, her insights into authoritarianism and her profound commitment to democracy and freedom</strong>.”– <span>Amanda Sloat, Special Assistant to President Biden and Senior Director for Europe at the National Security Council at White House</span>",
  },
  {
    img: "emine-dzhaparova.jpg",
    body: "“Ukraine is committed to the principles of international law and the UN Charter, including the UN Security Council Resolution on Women, Peace and Security. Since 2000, we have made a significant advance in promoting gender equality and empowerment of women. Today, <strong>Ukrainian women show a new gold standard of courage, dignity and determination</strong> from the very first day of Russia’s invasion, 30,000 women soldiers have been fighting shoulder-to-shoulder with men.” - <span>First Deputy Foreign Minister Dzhaparova (following the receipt of the Presidential Medal of Merits on behalf of all the women of Ukraine)</span>",
  },
  {
    img: "roberta_metsola.jpg",
    body: "“There is no time for complacency regarding a serious threat to the advancement of women’s rights nor is their time for pessimism. Together as leaders and outspoken defenders of the role of women in preventing conflict and peace negotiations, <strong>we have lessons to share with one another and leverage in abundance to counter ills that we will not tolerate</strong>.” - <span>President of the European Parliament, Roberta Metsola</span>",
  },
  {
    img: "president_vella.jpg",
    body: "“We believe in consistently promoting gender equality and women’s empowerment, as they are at the core of our European values. In addition, gender mainstreaming is intrinsic to government policy. The success of gender mainstreaming is only possible through strong political commitment, adequate institutional structures and procedures, as well as reinforced implementation across the policy areas. We also believe that <strong>investing in the economic and political empowerment of women yields substantial results</strong>.” – <span>President Vella</span>",
  },
  {
    img: "uduch_dengebau.jpg",
    body: "“The Women, Peace and Security Agenda is an essential tool in ensuring the rights and protection of women and girls. Ensuring that they are observed and appealed at all times and <strong>confirms that a meaningful and equitable role in decision-making is secured for women of all ages during all stages of conflict prevention, peacemaking and peacebuilding and post-conflict rehabilitation</strong>.” – <span>Vice President J. Uduch Sengebau Senior</span>",
  },
  {
    img: "jovana-marovic.jpg",
    body: "“Women are also under-represented in the political life and economic decision-making. We are all witnessing that there are many of women in important positions, but in the Western Balkans, we are still struggling to have more women in important positions. Therefore, let us all do are best to use this Forum, to draw attention to the importance of achieving peace with a particular emphasize on women and their position and role in these turbulent times because I am convinced that <strong>women’s participation and solutions can make the world a better and safer place to live</strong>.” – <span>Deputy Prime Minister Marović</span>",
  },
  {
    img: "artan_grubi.jpg",
    body: "“The echoes of the wounds of thousands of girls and women who became victims of violence against them is so loud. <strong>Healing their wounds is an important social mission, not just an individual one</strong>.” – <span>Deputy Prime Minister Grubi</span>",
  },
  {
    img: "irene_fellin.jpg",
    body: "“It [Resolution 1325] also recognized the diverse role women play at resolution of conflict and the restoration of the rule of law, governance and democracy. <strong>Women are mediators of disputes, reinforcement of community resilience, managers of critical resources and defenders of freedom, often at great personal risk<strong>.” – <span>Irene Fellin, NATO Secretary General’s Special Representative for Women, Peace and Security</span>",
  },
  {
    img: "wesley_clark.jpg",
    body: "“We know that women pay the highest personal prices in times of crisis. They are ideally positioned to be the pillars of conceptualizing and implementing solutions, and this means they must be at the tables where policies and decision are made. That’s the only way to foster <strong>strong and resilient democracies</strong>.” – <span>Wesley Clark, Former NATO Supreme Allied Commander Europe from 1997 to 2000</span>",
  },
  {
    img: "thomas_daschle.jpg",
    body: "“Gender equality is not a zero-sum game. <strong>When women are empowered, the entire society benefits</strong>. Evidence has shown that countries who have less inequality in society are more prosperous, more peaceful.” – <span>Thomas Daschle, U.S. Senator</span>",
  },
  {
    img: "carlien_shceele.jpg",
    body: "“Joining up is rising up! Now, if you want more women engaged in peace processes, attention must also be brought at <strong>ending genderbased violence</strong>.” – <span>Carlien Scheele, Director at European Institute for Gender Equality</span>",
  },
  {
    img: "mateja_norcic.jpg",
    body: "“The pandemic and the overwhelmed public sector, the rising numbers of conflicts and the rising fuel and food prices resulting from <strong>senseless war in Ukraine has disproportionally affected women</strong>.” – <span>Mag. Mateja Norčič Štamcar, Acting Director – General of the Directorate for Common Foreign and Security Policy in Slovenia</span>",
  },
  {
    img: "saranda_bogujevci.png",
    body: "“In order to ensure gender equality and equity, we need to invest in educating and raising awareness of our future generations. We need to work together side-by-side, women and men, to change this mindset and break the cycle of discrimination for our future generation of girls.” – <span>Saranda Bogujevci, Deputy Speaker of the Parliament of the Republic of Kosovo</span>",
  },
];
