export interface SelectArray {
  name: string;
  value: number | string;
}

export const delegationRolesArray: SelectArray[] = [
  { name: "Forum Speaker", value: 0 },
  { name: "Official Delegation Members", value: 1 },
  { name: "Unarmed Security Officer", value: 2 },
  { name: "Armed Security Officers", value: 3 },
  { name: "Driver", value: 5 },
  { name: "Interpreters", value: 6 },
  { name: "Photographer", value: 7 },
  { name: "Videographer", value: 8 },
  { name: "Public Relations Officer", value: 9 },
  { name: "Advisor", value: 10 },
  { name: "Other", value: 11 },
];


