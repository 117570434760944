import { makeAutoObservable, runInAction } from "mobx";
import { RegistrationUser, Roles, User, UserFormValues } from "../models/User";
import agent from "../api/agent";
import { store } from "./store";
import { DelegationDetails } from "../models/RegisterDelegation";

export default class UserStore {
  user: User | null = null;
  registerEmail = "";
  resendConfirmEmail = false;
  confirmEmail = false;

  toggleResendConfirmEmail = (state: boolean) => {
    this.resendConfirmEmail = state;
  };

  setRegisterEmail = (state: string) => {
    this.registerEmail = state;
  };

  toggleConfirmEmail = (state: boolean) => {
    this.confirmEmail = state;
  };

  constructor() {
    makeAutoObservable(this);
  }

  get isAdministrator() {
    return this.user?.userRoles.includes(Roles.Administrator);
  }

  get isLoggedIn() {
    return !!this.user;
  }

  getUser = async () => {
    try {
      const user = await agent.Account.current();
      runInAction(() => {
        this.user = user;
      });
    } catch (err) {
      console.log(err);
    }
  };

  login = async (creds: UserFormValues) => {
    const user = await agent.Account.login(creds);
    store.commonStore.setToken(user.token);
    runInAction(() => {
      this.user = user;
    });
  };

  sendResetPasswordRequest = async (email: string) => {
    await agent.Account.requestPasswordChange(email);
  };

  sendCodeVerificationForEmailChange = async (data: {
    email: string;
    code: string;
  }) => {
    await agent.Account.sendCodeVerificationForEmailChange(data);
  };
  changePassword = async (data: {
    email: string;
    code: string;
    password: string;
  }) => {
    await agent.Account.changePassword(data);
  };

  logout = () => {
    store.commonStore.setToken(null);
    window.localStorage.removeItem("jwt");
    this.user = null;
  };

  register = async (userValues: RegistrationUser) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(userValues)) {
      formData.append(key, value);
    }
    await agent.Account.register(formData);
  };

  registerDelegation = async (
    users: RegistrationUser[],
    delegationDetails: DelegationDetails
  ) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(delegationDetails)) {
      if (key === "driverList") {
        delegationDetails[key].forEach((driver, index) => {
          for (const [driverKey, driverValue] of Object.entries(driver)) {
            formData.append(`DriverList[${index}].${driverKey}`, driverValue);
          }
        });
      } else {
        formData.append(key, value);
      }
    }

    users.forEach((user, index) => {
      for (const [key, value] of Object.entries(user)) {
        formData.append(`UserDelegationList[${index}].${key}`, value);
      }
    });

    await agent.DelegationApi.registerDelegation(formData);
  };
}
