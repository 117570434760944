import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import { Avatar, Checkbox, IconButton, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import PageLoader from "../components/loaders/PageLoader";
import { Ticket } from "../../app/models/Ticket";
import { EmailActive, User } from "../../app/models/User";
import { observer } from "mobx-react-lite";
import TicketConfirmModal from "../components/TicketConfirmModal";
import "./adminStyle.scss";
import MUIDataTable from "mui-datatables";
import { useTranslation } from "react-i18next";
import { FaXmark } from "react-icons/fa6";

function Ambassadors() {
  const { manageUsersStore, delegationStore } = useStore();
  const { delegation, setDelegation } = delegationStore;
  const { getAllUsers, deleteUser, attendance, activateEmail } =
    manageUsersStore;
  const [rowsDeleted, setRowsDeleted] = useState<any>();
  const [loadingIntital, setLoadingInitial] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [ticket, setTicket] = useState<Ticket>({
    userId: "",
    attendance: false,
  });
  const [emailConfirmation, setEmailConfirmation] = useState<EmailActive>({
    userId: "",
    emailConfirmed: false,
  });
  const InitialModalValues = {
    delete: false,
    confirmAttendance: false,
    cancelAttendance: false,
    filterDeleted: false,
    confirmEmail: false,
  };
  const [show, setShow] = useState(InitialModalValues);

  useEffect(() => {
    manageUsersStore.loadAmbassadors().finally(() => setLoadingInitial(false));
  }, [manageUsersStore]);

  if (loadingIntital) return <PageLoader />;

  const handleEmailConfirmation = async (): Promise<void> => {
    setLoading(true);
    await activateEmail(emailConfirmation).finally(() => {
      setShow(InitialModalValues);
      setLoading(false);
    });
  };

  const getSecurityData = (user: User) => {
    if (user.securityExtra) {
      return <p>Security: {user.securityExtra}</p>;
    } else if (user.securityWeaponMark) {
      return (
        <div className="paragraph-padding-remove">
          <p>Weapon Mark: {user.securityWeaponMark}</p>
          <p>Weapon Nr: {user.securityWeaponNumber}</p>
          <p>Weapon Ammo: {user.securityWeaponAmmo}</p>
        </div>
      );
    } else return "No Extra's";
  };

  const getCoutryInstitution = (user: User) => {
    if (!user.country && !user.institution) {
      return "Not Added";
    } else
      return (
        <div className="paragraph-padding-remove">
          <p>
            {user.country ? user.country : "Not Added"} /{" "}
            {user.institution ? user.institution : "Not Added"}
          </p>
        </div>
      );
  };

  const data = getAllUsers
    .filter((user) => user.isDeleted === show.filterDeleted)
    .map((m) => ({
      id: m.id,
      photo: <Avatar alt={m.firstName} src={m.photo} />,
      fullName: `${m.firstName} ${m.lastName}`,
      birthDate: dayjs(m.birthDate).format("DD/MM/YYYY"),
      email: m.email,
      country: getCoutryInstitution(m),
      userRoles: m.userRoles
        .map((role) => role.replace(/([A-Z])/g, " $1"))
        .join(","),
      extra: getSecurityData(m),
      hasAttended: m.hasAttended ? t("YES") : t("NO"),
      emailConfirmed: (
        <Checkbox
          checked={m.emailConfirmed}
          onChange={(e) => {
            setEmailConfirmation({
              userId: m.id,
              emailConfirmed: e.target.checked,
            });
            handleShow("confirmEmail", true);
          }}
        />
      ),
      action: (
        <Tooltip
          onClick={() =>
            m.hasAttended ? openAttendance(m, false) : openAttendance(m, true)
          }
          title={
            m.hasAttended ? t("CANCEL_ATTENDANCE") : t("CONFIRM_ATTENDANCE")
          }
        >
          <IconButton>
            {m.hasAttended ? <CloseIcon /> : <CheckIcon />}
          </IconButton>
        </Tooltip>
      ),
    }));

  const columns: any = [
    { name: "photo", label: "Photo" },
    { name: "fullName", label: "Full Name" },
    { name: "birthDate", label: t("BIRTH_DATE") },
    { name: "email", label: t("EMAIL") },
    { name: "country", label: "Country / Institution" },
    { name: "userRoles", label: t("ROLES") },
    { name: "extra", label: "Extra" },
    { name: "hasAttended", label: t("ATTENDANCE") },
  ];

  if (!show.filterDeleted) {
    columns.push({ name: "emailConfirmed", label: t("CONFIRM_EMAIL") });
    columns.push({
      name: "action",
      label: t("ACTION"),
      options: {
        filter: false,
        sort: false,
      },
    });
  }

  const handleShow = (
    action:
      | "delete"
      | "cancelAttendance"
      | "confirmAttendance"
      | "confirmEmail"
      | "filterDeleted",
    state: boolean
  ): void => {
    setShow({ ...show, [action]: state });
  };

  const openAttendance = (user: User, confirm: boolean): void => {
    setShow({
      ...show,
      confirmAttendance: confirm,
      cancelAttendance: !confirm,
    });
    setTicket({ userId: user.id, attendance: confirm });
  };

  const openConfrimDelete = (rowsDeleted: any) => {
    setShow({ ...show, delete: true });
    setRowsDeleted(rowsDeleted);
  };

  const handleDelete = async (): Promise<void> => {
    setLoading(true);
    for await (const d of rowsDeleted.data) {
      var id = data[d.dataIndex].id;
      await deleteUser(id);
    }
    setRowsDeleted(null);
    setShow(InitialModalValues);
    setLoading(false);
  };

  const handleAttendance = async (): Promise<void> => {
    setLoading(true);
    attendance(ticket).finally(() => {
      setShow(InitialModalValues);
      setLoading(false);
    });
  };

  const modals = [
    {
      show: show.delete,
      hide: () => handleShow("delete", false),
      modalBody: t("CONFIRM_DELETE_1"),
      onClick: handleDelete,
      btnText: t("DELETE"),
    },
    {
      show: show.confirmAttendance,
      hide: () => handleShow("confirmAttendance", false),
      modalBody: t("CONFIRM_ATTENDANCE_USER_1"),
      onClick: handleAttendance,
      btnText: t("CONFIRM"),
    },
    {
      show: show.cancelAttendance,
      hide: () => handleShow("cancelAttendance", false),
      modalBody: t("CANCEL_ATTENDANCE_USER_1"),
      onClick: handleAttendance,
      btnText: t("CANCEL_ATTENDANCE"),
    },
    {
      show: show.confirmEmail,
      hide: () => handleShow("confirmEmail", false),
      modalBody: emailConfirmation.emailConfirmed
        ? t("CONFIRM_EMAIL_USER_1")
        : t("DEACTIVATE_EMAIL_USER_1"),
      onClick: handleEmailConfirmation,
      btnText: emailConfirmation.emailConfirmed
        ? t("ACTIVATE")
        : t("DEACTIVATE"),
    },
  ];

  function customToolBar() {
    return (
      <Tooltip title={t("FILTER_DELETED_USERS")}>
        <Checkbox
          checked={show.filterDeleted}
          onClick={() => handleShow("filterDeleted", !show.filterDeleted)}
          sx={{ "& .MuiSvgIcon-root": { fontSize: 21 } }}
        />
      </Tooltip>
    );
  }

  return (
    <div className="main">
      <div className="Mui-datatable-user">
        <MUIDataTable
          title={
            <h4>
              {t("Ambassador accounts")}
              {delegation && (
                <span>
                  {" "}
                  of "{delegation.delegationName}" delegation
                  <Tooltip
                    onClick={() => setDelegation(undefined)}
                    title={"Remove Delegation"}
                  >
                    <IconButton>
                      <FaXmark size={20} color="black" />
                    </IconButton>
                  </Tooltip>
                </span>
              )}
            </h4>
          }
          data={data}
          columns={columns}
          options={{
            responsive: "vertical",
            viewColumns: true,
            selectableRows: !show.filterDeleted ? "multiple" : "none",
            rowsPerPage: 500,
            rowsPerPageOptions: [500, 1000, 1500],
            tableBodyHeight: "auto",
            onRowsDelete: openConfrimDelete,
            customToolbar: () => customToolBar(),
          }}
        />
      </div>

      {modals.map((modal, index) => (
        <TicketConfirmModal
          key={index}
          show={modal.show}
          hide={modal.hide}
          modalBody={modal.modalBody}
          loading={loading}
          onClick={modal.onClick}
          btnText={modal.btnText}
        />
      ))}
    </div>
  );
}
export default observer(Ambassadors);
