import { useState } from "react";
import { Drivers } from "../../../app/models/Drivers";
import { useStore } from "../../../app/stores/store";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./driverStyle.scss";
import PhoneInput from "react-phone-input-2";

interface CreateDriverProps {
  handleCloseDriver: () => void;
}

function CreateDriver({ handleCloseDriver }: CreateDriverProps) {
  const DRIVER_VALUES: Drivers = {
    id: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    occupied: false,
  };

  const { driverStore } = useStore();
  const [driver, setDriver] = useState(DRIVER_VALUES);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const { t } = useTranslation();

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    setDriver({ ...driver, [name]: value });
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
      setLoadingBtn(true);
      driverStore
        .createDriver(driver)
        .then(() => {
          setDriver(DRIVER_VALUES);
          handleCloseDriver();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoadingBtn(false);
        });
  }

  return (
    <div className="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title>{t("CREATE_DRIVER")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="row-agenda pt-3">
            <Col md={12}>
              <TextField
                fullWidth
                label={t("FIRST_NAME")}
                type="text"
                name="firstName"
                value={driver.firstName}
                onChange={handleInputChange}
                required
              />
            </Col>
            <Col md={12}>
              <TextField
                fullWidth
                label={t("LAST_NAME")}
                type="text"
                name="lastName"
                value={driver.lastName}
                onChange={handleInputChange}
                required
              />
            </Col>
            <Col md={12}>
              <PhoneInput
                disableCountryGuess
                enableSearch
                country="xk"
                specialLabel={t("PHONE_NUMBER")}
                inputClass="phone-input-style"
                inputStyle={{ width: "100%", height: "54px" }}
                value={driver.phoneNumber}
                onChange={(value) =>
                  setDriver({ ...driver, phoneNumber: value })
                }
              />
            </Col>
          </Row>
          <div className="mt-3 gap-2 d-flex">
            <Button disabled={loadingBtn} type="submit" variant="success">
              {loadingBtn ? (
                <Spinner animation="border" size="sm" variant="light" />
              ) : (
                t("CREATE")
              )}
            </Button>
            <Button variant="danger" onClick={handleCloseDriver}>
              {t("CLOSE")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </div>
  );
}
export default CreateDriver;
