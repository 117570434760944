import React from "react";
import { Button, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

function UnsuccessfulRegister() {
  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center pb-5 login"
    >
      <Card
        style={{ width: "800px" }}
        className="newFont prevent-select card-shadow login-card-size login-card"
      >
        <Card.Header>
          <p className="login-card-title">Register Status</p>
        </Card.Header>
        <Card.Body className="success-login-card-padding card-font-size">
          <Card.Title className="d-flex align-center align-items-center">
            <i
              style={{ fontSize: "60px" }}
              className="fa-regular fa-circle-xmark pe-3 text-danger"
            />
            <h4 className="successful-registration-text">
              Registration attempt failed, please try again.
            </h4>
          </Card.Title>
          <div className="pt-3 d-flex flex-column">
            <Link to="/login">Click here to login!</Link>
            <Button
              onClick={() => {
                window.history.go(-1);
                return false;
              }}
            >
              Register
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default UnsuccessfulRegister;
