import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useStore } from "../../app/stores/store";
import "./login.scss";
import { Link } from "react-router-dom";
import { MdAccountCircle } from "react-icons/md";
import {
  AiOutlineMail,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import ResendConfirmEmail from "./ResendConfirmEmail";

function Login() {
  const VALUES = {
    email: "",
    password: "",
  };
  const [user, setUser] = useState(VALUES);
  const { userStore } = useStore();
  const { login, setRegisterEmail, toggleResendConfirmEmail } = userStore;
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const [error, setError] = useState("");

  // todo: replace any with correct type
  const handleSubmit = (e: any): void => {
    setLoadingBtn(true);
    e.preventDefault();
    login(user)
      .catch((error) => {
        setLoadingBtn(false);
        setError(error.response.data.errors);
      })
      .finally(() => setLoadingBtn(false));
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  function handleResendConfirm() {
    setRegisterEmail(user.email);
    toggleResendConfirmEmail(true);
  }

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 login">
      <Card className="login-card card-style d-flex justify-content-center align-items-center">
        <Card.Body>
          <Card.Title className="text-center mb-4">
            <MdAccountCircle className="icon-color" size={120} />
          </Card.Title>
          <Form onSubmit={handleSubmit} className="screen-1">
            <div className="email">
              <label>{t("EMAIL_ADDRESS")}</label>
              <div className="sec-2 d-flex align-items-center gap-1">
                <AiOutlineMail className="show-hide md hydrated" />
                <input
                  type="email"
                  name="email"
                  onChange={handleInputChange}
                  placeholder="example@gmail.com"
                />
              </div>
            </div>

            <div className="password">
              <label>{t("PASSWORD")}</label>
              <div className="sec-2 d-flex align-items-center gap-1">
                <RiLockPasswordLine />
                <input
                  className=""
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={handleInputChange}
                  placeholder="············"
                />
                {showPassword ? (
                  <AiOutlineEye
                    className="align-right"
                    onClick={() => setShowPassword(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="align-right"
                    onClick={() => setShowPassword(true)}
                  />
                )}
              </div>
            </div>
            <div className="text-center">
              <Link to="/reset-password">{t("Forgot Password?")}</Link>
            </div>
            <div className="mt-3">
              <button
                disabled={loadingBtn}
                type="submit"
                className="login-button"
              >
                {loadingBtn ? (
                  <Spinner animation="border" size="sm" variant="light" />
                ) : (
                  t("LOGIN")
                )}
              </button>
            </div>
            {error === "email_not_confirmed" && (
              <span
                onClick={handleResendConfirm}
                className="confirmEmail text-center"
              >
                Resend Confirm Email
              </span>
            )}
            <div className="mt-3 text-center">
              {t("DONT_HAVE_ACCOUNT")} <Link to="/register">{t("HERE")}</Link>
            </div>
          </Form>
        </Card.Body>
      </Card>
      <ResendConfirmEmail />
    </div>
  );
}

export default observer(Login);
